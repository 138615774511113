export interface MouseFollowerOptions {
    hiddenState: string,
    textState: string,
    activeState: string|null,
    mediaState: string,
    stateDetection: {[key: string]: string},
    visible: boolean,
    visibleOnState: boolean,
    speed: number,
    ease: string,
    overwrite: boolean,
    skewingText: number,
    skewingMedia: number,
    skewingDelta: number,
    skewingDeltaMax: number,
    stickDelta: number,
    showTimeout: number,
    hideOnLeave: boolean,
    hideTimeout: number,
    hideMediaTimeout: number,
}

export interface Position {
    x: number,
    y: number,
}

export class GsapSetter {
  x: Function = () => {};
  y: Function = () => {};
  rotation: Function = () => {};
  scaleX: Function = () => {};
  scaleY: Function = () => {};
  wc: Function = () => {};
  inner: {rotation: Function} = {rotation: () => {}};
}

export class Event {
  mouseleave: (ev: MouseEvent) => void = () => {};
  mouseenter: (ev: MouseEvent) => void = () => {};
  mousedown: (ev: MouseEvent) => void = () => {};
  mouseup: (ev: MouseEvent) => void = () => {};
  mousemoveOnce: (ev: MouseEvent) => void = () => {};
  mousemove: (ev: MouseEvent) => void = () => {};
  mouseover: (ev: MouseEvent) => void = () => {};
  mouseout: (ev: MouseEvent) => void = () => {};
}

export class CursorComponents {
  el: HTMLElement;
  inner: HTMLElement;
  text: HTMLElement;
  media: HTMLElement;
  mediaBox: HTMLElement;


  constructor(cursor: HTMLElement, inner: HTMLElement, text: HTMLElement, media: HTMLElement, mediaBox: HTMLElement) {
    this.el = cursor;
    this.inner = inner;
    this.text = text;
    this.media = media;
    this.mediaBox = mediaBox;
  }
}
