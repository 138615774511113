import {createWebHistory, createRouter, NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import routes from '@/router/routes';
import i18n from '@/plugins/i18n';
import languages from '@/translations';
import {trackRouter} from 'vue-gtag-next';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },
});

const supportedLangs = Object.keys(languages) as (keyof typeof languages)[];
type SupportedLangs = typeof supportedLangs[number];

function checkLanguage(to: RouteLocationNormalized, next: NavigationGuardNext) {
  const lang = to.params.lang as SupportedLangs || 'en';
  i18n.global.locale = lang;

  // True if lang is not supported or empty
  if (!supportedLangs.includes(to.params.lang as SupportedLangs) && to.params.lang != '') {
    i18n.global.locale = 'en';
    return {
      path: '/en',
      replace: true,
    };
  }

  return next;
}

router.beforeResolve(async (to, from, next) => {
  const nextRoute = checkLanguage(to, next) as NavigationGuardNext;

  next(nextRoute);
});

trackRouter(router);

export default router;
