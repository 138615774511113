<template>
  <span class="text-primary rotator-style">
    <Transition
      name="fade"
      mode="out-in"
    >
      <span :key="currentText">
        <b>{{ $t(currentText) }}</b>
      </span>
    </Transition>
  </span>
</template>

<script>
import LandingTextRotatorData from '@/pages/home/data/text-rotator';

export default {
  data: () => ({
    currentText: LandingTextRotatorData[0].value,
    interval: null,
  }),
  mounted() {
    this.changeText(LandingTextRotatorData);
  },
  beforeUnmount() {
    clearInterval( this.interval );
  },
  methods: {
    changeText(texts) {
      let i = 1;
      this.interval = setInterval(()=>{
        i === texts.length ? i = 0 : null;
        this.currentText = texts[i].value;
        i++;
      }, 4000);
    },
  },
};
</script>

<style scoped>
.rotator-style {
  opacity: 0.8;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
