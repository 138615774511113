// Linear interpolation
const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b;

const calcWinsize = () => ({width: window.innerWidth, height: window.innerHeight});

// Gets the mouse position
const getMousePos = (e: MouseEvent) => ({
  x: e.clientX,
  y: e.clientY,
});

const distance = (x1: number, y1: number, x2: number, y2: number) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.hypot(a, b);
};

// Gets adjusted bounding rect
const adjustBoundingRect = (bounds: DOMRect, scrollX: number, scrollY: number) => {
  const adjustedRect: DOMRect = {
    bottom: bounds.bottom,
    top: bounds.top + scrollY,
    left: bounds.left + scrollX,
    right: bounds.right,
    height: bounds.height,
    width: bounds.width,
    x: bounds.x,
    y: bounds.y,
    toJSON() {},
  };
  return adjustedRect;
};

export {
  lerp,
  calcWinsize,
  getMousePos,
  distance,
  adjustBoundingRect,
};
