import TextRotator from '@/models/text-rotator';

const landingTextRotatorData = [
  new TextRotator(
      'home.textRotator.one',
  ),
  new TextRotator(
      'home.textRotator.two',
  ),
  new TextRotator(
      'home.textRotator.three',
  ),
];

export default landingTextRotatorData;
