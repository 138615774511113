export interface renderedStyles {
    [key: string]: numericStateWithSpeed | any,
    tx: numericStateWithSpeed,
    ty: numericStateWithSpeed,
}

export class numericStateWithSpeed {
  previous: number;
  current: number;
  speed: number;

  constructor(previous: number, current: number, speed: number) {
    this.previous = previous;
    this.current = current;
    this.speed = speed;
  }
};
