<template>
  <v-icon
    v-if="arrowSxVisible"
    color="primary"
    class="arrow arrow-left bg-background"
    size="large"
    @click="onClickLeft()"
  >
    mdi-chevron-left
  </v-icon>

  <v-icon
    v-if="arrowDxVisible"
    color="primary"
    class="arrow arrow-right float-right bg-background"
    size="large"
    @click="onClickRight()"
  >
    mdi-chevron-right
  </v-icon>

  <div
    ref="tabs"
    class="tabs"
    @scroll="onScroll"
  >
    <template
      v-for="(work, index) in works"
      :key="index"
    >
      <WorksTimelineTab
        :tab-index="index + 1"
        :work="work"
      />
    </template>

    <span
      class="highlighter"
      :style="{ transform: highlighterTransform }"
    />
  </div>
</template>

<script>
import WorksData from '@/pages/home/data/works';
import WorksTimelineTab from '@/pages/home/components/WorksTimelineTab.vue';

export default {
  name: 'WorksTimelineTabsContainer',
  components: {
    WorksTimelineTab,
  },
  data: () => ({
    works: WorksData,
    highlighterTransform: 'translateX(0)',
    scrollPosition: 0,
    maxScrollPosition: 0,
    arrowSxVisible: false,
    arrowDxVisible: false,
    scrollRate: 2/3,
  }),
  computed: {
    highlighterChanged() {
      return this.$store.state.WorkTabManager.highlighterTransform;
    },
    screenSizeChanged() {
      return this.$store.state.ScreenResize.currentWidth;
    },
  },
  watch: {
    highlighterChanged() {
      this.highlighterTransform = this.$store.state.WorkTabManager.highlighterTransform;
    },
    screenSizeChanged() {
      this.onScroll();
    },
  },
  mounted() {
    this.onScroll();
  },
  methods: {
    onScroll() {
      const tabs = this.$refs.tabs;
      this.scrollPosition = tabs.scrollLeft;
      this.maxScrollPosition = tabs.scrollWidth - tabs.clientWidth;

      const isOverflowing = this.maxScrollPosition !== 0;
      this.arrowSxVisible = 0 < this.scrollPosition && isOverflowing ? true : false;
      this.arrowDxVisible = this.scrollPosition < this.maxScrollPosition && isOverflowing ? true : false;
    },
    onClickLeft() {
      const tabs = this.$refs.tabs;
      tabs.scrollBy({
        left: -tabs.clientWidth * this.scrollRate,
        behavior: 'smooth',
      });
    },
    onClickRight() {
      const tabs = this.$refs.tabs;
      tabs.scrollBy({
        left: tabs.clientWidth * this.scrollRate,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles/variables";

$highlighter-thickness: 2px;
$arrow-border-radius: 20px;

.tabs {
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  // Disables rubber banding / overscroll
  overscroll-behavior: none;
}

.highlighter {
  display: block;
  position: absolute;
  background: $work-timeline-tab-color;
  width: $work-timeline-tab-width;
  height: $highlighter-thickness;
  top: calc($work-timeline-tab-height - $highlighter-thickness);
  left: 0;
  transform: translateY(0);
  transition: transform 0.2s ease;
  transition-delay: 0.1s;
  z-index: 10;
}

.arrow {
  position: absolute;
  z-index: 100;
  margin-top: -2px;
  height: 42px !important;
}

.arrow-left {
  margin-left: -1px;
  box-shadow: 5px 0px 10px rgba(var(--v-theme-background), 1);
  border-top-right-radius: $arrow-border-radius;
  border-bottom-right-radius: $arrow-border-radius;
}

.arrow-right {
  right: 0;
  margin-right: -1px;
  box-shadow: -5px 0px 10px rgba(var(--v-theme-background), 1);
  border-top-left-radius: $arrow-border-radius;
  border-bottom-left-radius: $arrow-border-radius;
}
</style>
