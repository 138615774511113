<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 639 324"
      xmlns="http://www.w3.org/2000/svg"
      class="proficiency"
      width="100%"
    >
      <g
        id="red-pen"
        class="proficiency_red-pen"
      >
        <path
          id="Vector"
          d="M595.145 215.813L585.388 214.752L577.537 286.977L587.294 288.037L595.145 215.813Z"
          fill="#FF5252"
        />
        <path
          id="Vector_2"
          d="M587.3 290.032C587.227 290.032 587.154 290.032 587.081 290.021L577.324 288.96C576.798 288.903 576.315 288.639 575.983 288.226C575.651 287.814 575.496 287.287 575.552 286.76L583.402 214.536C583.459 214.01 583.723 213.527 584.136 213.195C584.548 212.863 585.075 212.708 585.602 212.764L595.359 213.824C595.886 213.881 596.368 214.145 596.701 214.557C597.033 214.97 597.188 215.497 597.132 216.024L589.281 288.248C589.228 288.737 588.997 289.189 588.631 289.518C588.266 289.847 587.792 290.03 587.3 290.032ZM579.744 285.199L585.524 285.828L592.943 217.581L587.163 216.952L579.744 285.199Z"
          fill="#171819"
        />
        <path
          id="Vector_3"
          d="M595.146 215.813L585.389 214.752L584.59 222.099L594.347 223.159L595.146 215.813Z"
          fill="#2D425E"
        />
        <path
          id="Vector_4"
          d="M594.354 225.155C594.282 225.155 594.209 225.155 594.136 225.144L584.379 224.083C583.852 224.026 583.37 223.762 583.038 223.35C582.705 222.937 582.55 222.41 582.606 221.883L583.406 214.536C583.463 214.01 583.727 213.527 584.14 213.195C584.552 212.863 585.079 212.708 585.606 212.764L595.363 213.824C595.89 213.881 596.372 214.145 596.704 214.557C597.037 214.97 597.192 215.497 597.136 216.024L596.336 223.371C596.283 223.86 596.051 224.312 595.686 224.642C595.32 224.971 594.846 225.153 594.354 225.155ZM586.799 220.322L592.579 220.951L592.946 217.581L587.166 216.952L586.799 220.322Z"
          fill="#171819"
        />
      </g>
      <g
        id="yellow-pen"
        class="proficiency_yellow-pen"
      >
        <path
          id="Vector_5"
          d="M568.306 201.119L558.559 202.263L567.029 274.418L576.776 273.273L568.306 201.119Z"
          fill="#FFA364"
        />
        <path
          id="Vector_6"
          d="M568.306 201.119L558.559 202.263L559.42 209.603L569.167 208.458L568.306 201.119Z"
          fill="#2AA1FF"
        />
        <path
          id="Vector_7"
          d="M559.42 211.6C558.93 211.6 558.457 211.42 558.091 211.094C557.725 210.768 557.491 210.32 557.434 209.833L556.572 202.493C556.541 202.232 556.562 201.968 556.634 201.715C556.705 201.462 556.826 201.226 556.989 201.02C557.151 200.814 557.353 200.642 557.582 200.514C557.812 200.385 558.064 200.304 558.325 200.273L568.072 199.129C568.333 199.098 568.597 199.119 568.85 199.191C569.103 199.262 569.339 199.383 569.545 199.546C569.751 199.708 569.923 199.91 570.051 200.139C570.18 200.369 570.261 200.621 570.292 200.882L571.153 208.222C571.215 208.749 571.065 209.278 570.736 209.694C570.407 210.111 569.927 210.379 569.4 210.441L559.653 211.586C559.576 211.595 559.498 211.6 559.42 211.6ZM560.778 204.013L561.173 207.38L566.947 206.702L566.553 203.335L560.778 204.013Z"
          fill="#171819"
        />
        <path
          id="Vector_8"
          d="M567.028 276.418C566.538 276.418 566.065 276.238 565.699 275.912C565.333 275.586 565.099 275.138 565.042 274.651L556.572 202.497C556.541 202.236 556.562 201.972 556.634 201.719C556.705 201.466 556.826 201.23 556.989 201.024C557.151 200.818 557.353 200.646 557.582 200.518C557.812 200.389 558.064 200.308 558.325 200.277L568.072 199.133C568.333 199.102 568.597 199.123 568.85 199.195C569.103 199.266 569.339 199.387 569.545 199.55C569.751 199.712 569.923 199.914 570.051 200.144C570.18 200.373 570.261 200.625 570.292 200.886L578.762 273.04C578.793 273.301 578.772 273.565 578.7 273.818C578.629 274.071 578.508 274.307 578.345 274.513C578.183 274.719 577.981 274.891 577.751 275.019C577.522 275.148 577.27 275.229 577.009 275.26L567.262 276.404C567.184 276.413 567.106 276.418 567.028 276.418ZM560.778 204.018L568.778 272.199L574.553 271.522L566.553 203.34L560.778 204.018Z"
          fill="#171819"
        />
      </g>
      <g
        id="pc"
        class="proficiency_pc"
      >
        <path
          id="Vector_9"
          d="M629.492 305.303H9.16101C4.19045 305.303 0.161011 309.332 0.161011 314.303C0.161011 319.274 4.19045 323.303 9.16101 323.303H629.492C634.463 323.303 638.492 319.274 638.492 314.303C638.492 309.332 634.463 305.303 629.492 305.303Z"
          fill="#C9C9C9"
        />
        <path
          id="Vector_10"
          d="M125.891 230.289C121.314 230.254 116.937 228.412 113.713 225.163C110.489 221.915 108.68 217.523 108.68 212.946C108.68 208.37 110.489 203.978 113.713 200.73C116.937 197.481 121.314 195.639 125.891 195.604H223.925V230.289H125.891Z"
          fill="#CECECE"
        />
        <path
          id="Vector_11"
          d="M125.891 189.6H229.6V236.285H125.891C119.7 236.285 113.763 233.826 109.386 229.448C105.008 225.071 102.549 219.134 102.549 212.943C102.549 209.878 103.153 206.842 104.326 204.01C105.499 201.178 107.218 198.605 109.385 196.437C111.553 194.269 114.126 192.55 116.958 191.377C119.79 190.204 122.826 189.6 125.891 189.6Z"
          fill="white"
        />
        <path
          id="Vector_12"
          d="M223.925 209.567H119.549C119.019 209.567 118.51 209.356 118.135 208.981C117.76 208.606 117.549 208.097 117.549 207.567C117.549 207.037 117.76 206.528 118.135 206.153C118.51 205.778 119.019 205.567 119.549 205.567H223.925C224.455 205.567 224.964 205.778 225.339 206.153C225.714 206.528 225.925 207.037 225.925 207.567C225.925 208.097 225.714 208.606 225.339 208.981C224.964 209.356 224.455 209.567 223.925 209.567Z"
          fill="#171819"
        />
        <path
          id="Vector_13"
          d="M223.925 220.158H119.549C119.019 220.158 118.51 219.947 118.135 219.572C117.76 219.197 117.549 218.688 117.549 218.158C117.549 217.628 117.76 217.119 118.135 216.744C118.51 216.369 119.019 216.158 119.549 216.158H223.925C224.455 216.158 224.964 216.369 225.339 216.744C225.714 217.119 225.925 217.628 225.925 218.158C225.925 218.688 225.714 219.197 225.339 219.572C224.964 219.947 224.455 220.158 223.925 220.158Z"
          fill="#171819"
        />
        <path
          id="Vector_14"
          d="M223.925 232.289H125.891C120.785 232.252 115.901 230.198 112.303 226.575C108.706 222.951 106.687 218.052 106.687 212.946C106.687 207.84 108.706 202.942 112.303 199.318C115.901 195.695 120.785 193.641 125.891 193.604H223.925C224.455 193.604 224.964 193.815 225.339 194.19C225.714 194.565 225.925 195.074 225.925 195.604V230.289C225.925 230.819 225.714 231.328 225.339 231.703C224.964 232.078 224.455 232.289 223.925 232.289ZM125.891 197.6C121.843 197.633 117.973 199.264 115.122 202.137C112.271 205.011 110.672 208.895 110.672 212.943C110.672 216.99 112.271 220.874 115.122 223.748C117.973 226.621 121.843 228.252 125.891 228.285H221.925V197.6H125.891Z"
          fill="#171819"
        />
        <path
          id="Vector_15"
          d="M125.891 230.289C121.314 230.254 116.937 228.412 113.713 225.163C110.489 221.915 108.68 217.523 108.68 212.946C108.68 208.37 110.489 203.978 113.713 200.73C116.937 197.481 121.314 195.639 125.891 195.604H225.6L229.6 189.604H125.891C119.7 189.604 113.763 192.063 109.386 196.441C105.008 200.818 102.549 206.755 102.549 212.946C102.549 216.011 103.153 219.047 104.326 221.879C105.499 224.711 107.218 227.284 109.385 229.452C111.553 231.62 114.126 233.339 116.958 234.512C119.79 235.685 122.826 236.289 125.891 236.289H229.6L225.6 230.289H125.891Z"
          fill="#FF3E3E"
        />
        <path
          id="Vector_16"
          d="M229.6 238.289H125.891C119.197 238.247 112.792 235.558 108.074 230.81C103.355 226.062 100.707 219.64 100.707 212.946C100.707 206.253 103.355 199.831 108.074 195.083C112.792 190.335 119.197 187.646 125.891 187.604H229.6C229.962 187.604 230.317 187.702 230.628 187.888C230.938 188.074 231.192 188.341 231.363 188.66C231.534 188.979 231.615 189.339 231.597 189.7C231.58 190.062 231.465 190.412 231.264 190.713L227.264 196.713C227.081 196.987 226.834 197.212 226.544 197.367C226.253 197.523 225.929 197.604 225.6 197.604H125.891C121.843 197.637 117.973 199.268 115.122 202.141C112.271 205.015 110.672 208.899 110.672 212.946C110.672 216.994 112.271 220.878 115.122 223.752C117.973 226.625 121.843 228.256 125.891 228.289H225.6C225.929 228.289 226.253 228.37 226.544 228.526C226.834 228.681 227.081 228.906 227.264 229.18L231.264 235.18C231.465 235.481 231.58 235.831 231.597 236.193C231.615 236.554 231.534 236.914 231.363 237.233C231.192 237.552 230.938 237.819 230.628 238.005C230.317 238.191 229.962 238.289 229.6 238.289ZM125.891 191.6C120.256 191.639 114.865 193.904 110.894 197.902C106.923 201.901 104.694 207.307 104.694 212.943C104.694 218.578 106.923 223.984 110.894 227.983C114.865 231.981 120.256 234.246 125.891 234.285H225.867L224.533 232.285H125.891C120.785 232.248 115.901 230.194 112.303 226.571C108.706 222.947 106.687 218.048 106.687 212.943C106.687 207.837 108.706 202.938 112.303 199.314C115.901 195.691 120.785 193.637 125.891 193.6H224.533L225.867 191.6H125.891Z"
          fill="#171819"
        />
        <g
          id="Group_2"
          opacity="0.28"
        >
          <path
            id="Vector_17"
            opacity="0.28"
            d="M207.305 189.604H188.914V236.289H207.305V189.604Z"
            fill="#171819"
          />
          <path
            id="Vector_18"
            opacity="0.28"
            d="M207.305 238.289H188.914C188.384 238.289 187.875 238.078 187.5 237.703C187.125 237.328 186.914 236.819 186.914 236.289V189.6C186.914 189.07 187.125 188.561 187.5 188.186C187.875 187.811 188.384 187.6 188.914 187.6H207.305C207.835 187.6 208.344 187.811 208.719 188.186C209.094 188.561 209.305 189.07 209.305 189.6V236.285C209.306 236.548 209.254 236.808 209.154 237.052C209.054 237.295 208.906 237.516 208.721 237.702C208.535 237.888 208.314 238.036 208.071 238.136C207.828 238.237 207.568 238.289 207.305 238.289ZM190.914 234.289H205.305V191.6H190.914V234.289Z"
            fill="#171819"
          />
        </g>
        <path
          id="Vector_19"
          d="M218.935 236.289H29.031C25.297 236.289 22.27 239.316 22.27 243.05V268.085C22.27 271.819 25.297 274.846 29.031 274.846H218.935C222.669 274.846 225.696 271.819 225.696 268.085V243.05C225.696 239.316 222.669 236.289 218.935 236.289Z"
          fill="#50B56F"
        />
        <path
          id="Vector_20"
          d="M218.935 276.846H29.035C26.7122 276.843 24.4852 275.92 22.8426 274.277C21.2 272.635 20.2759 270.408 20.273 268.085V243.05C20.2759 240.727 21.2 238.5 22.8426 236.858C24.4852 235.216 26.7122 234.292 29.035 234.289H218.935C221.258 234.292 223.485 235.216 225.127 236.858C226.769 238.5 227.693 240.727 227.696 243.05V268.085C227.693 270.408 226.769 272.635 225.127 274.277C223.485 275.919 221.258 276.843 218.935 276.846ZM29.035 238.289C27.7726 238.29 26.5623 238.792 25.6696 239.685C24.7769 240.577 24.2746 241.788 24.273 243.05V268.085C24.2746 269.347 24.7769 270.558 25.6696 271.45C26.5623 272.343 27.7726 272.845 29.035 272.846H218.935C220.197 272.844 221.407 272.342 222.3 271.45C223.192 270.557 223.694 269.347 223.696 268.085V243.05C223.694 241.788 223.192 240.578 222.3 239.685C221.407 238.793 220.197 238.291 218.935 238.289H29.035Z"
          fill="#171819"
        />
        <g
          id="Group_3"
          opacity="0.28"
        >
          <path
            id="Vector_21"
            opacity="0.28"
            d="M218.935 236.289H188.914V251.289C188.914 253.53 189.355 255.748 190.212 257.818C191.07 259.888 192.326 261.769 193.911 263.353C195.495 264.938 197.376 266.194 199.446 267.052C201.516 267.909 203.735 268.35 205.975 268.35H225.683C225.683 268.263 225.696 268.177 225.696 268.089V243.05C225.696 241.257 224.984 239.537 223.716 238.269C222.448 237.001 220.728 236.289 218.935 236.289Z"
            fill="#171819"
          />
          <path
            id="Vector_22"
            opacity="0.28"
            d="M225.683 270.347H205.975C200.921 270.341 196.076 268.331 192.503 264.757C188.93 261.184 186.92 256.339 186.914 251.285V236.285C186.914 235.755 187.125 235.246 187.5 234.871C187.875 234.496 188.384 234.285 188.914 234.285H218.935C221.258 234.288 223.485 235.212 225.127 236.854C226.769 238.496 227.693 240.723 227.696 243.046V268.081C227.696 268.181 227.691 268.281 227.684 268.375C227.653 268.893 227.431 269.381 227.062 269.745C226.692 270.109 226.201 270.323 225.683 270.347ZM190.914 238.289V251.289C190.918 255.282 192.506 259.111 195.33 261.934C198.153 264.758 201.982 266.347 205.975 266.351H223.693V243.051C223.691 241.789 223.189 240.579 222.297 239.686C221.404 238.794 220.194 238.292 218.932 238.29L190.914 238.289Z"
            fill="#171819"
          />
        </g>
        <path
          id="Vector_23"
          d="M272.39 282.587H52.442C55.15 289.917 55.15 297.973 52.442 305.303H272.39C269.683 297.973 269.683 289.917 272.39 282.587Z"
          fill="#CECECE"
        />
        <path
          id="Vector_24"
          d="M278.355 276.543H46.485V282.587H278.355V276.543Z"
          fill="#5F5AFF"
        />
        <path
          id="Vector_25"
          d="M278.355 284.587H46.485C45.9546 284.587 45.4459 284.376 45.0708 284.001C44.6957 283.626 44.485 283.117 44.485 282.587V276.543C44.485 276.013 44.6957 275.504 45.0708 275.129C45.4459 274.754 45.9546 274.543 46.485 274.543H278.355C278.885 274.543 279.394 274.754 279.769 275.129C280.144 275.504 280.355 276.013 280.355 276.543V282.587C280.355 283.117 280.144 283.626 279.769 284.001C279.394 284.376 278.885 284.587 278.355 284.587ZM48.485 280.587H276.355V278.543H48.485V280.587Z"
          fill="#171819"
        />
        <path
          id="Vector_26"
          d="M278.355 305.303H46.485V311.347H278.355V305.303Z"
          fill="#F6F9FD"
        />
        <path
          id="Vector_27"
          d="M278.355 305.303H46.485V311.347H278.355V305.303Z"
          fill="#5F5AFF"
        />
        <path
          id="Vector_28"
          d="M278.355 313.347H46.485C45.9546 313.347 45.4459 313.136 45.0708 312.761C44.6957 312.386 44.485 311.877 44.485 311.347V305.3C44.485 304.77 44.6957 304.261 45.0708 303.886C45.4459 303.511 45.9546 303.3 46.485 303.3H278.355C278.885 303.3 279.394 303.511 279.769 303.886C280.144 304.261 280.355 304.77 280.355 305.3V311.344C280.355 311.607 280.304 311.867 280.204 312.11C280.103 312.353 279.956 312.574 279.77 312.76C279.585 312.946 279.364 313.094 279.121 313.194C278.878 313.295 278.618 313.347 278.355 313.347ZM48.485 309.347H276.355V307.3H48.485V309.347Z"
          fill="#171819"
        />
        <path
          id="Vector_29"
          d="M393.631 290L390.618 256.348H341.21L338.2 290C337.5 297.816 332.8 305.3 325.423 311.276L325.414 311.348H406.421L406.412 311.276C399.035 305.3 394.331 297.814 393.631 290Z"
          fill="#BFCFE2"
        />
        <g
          id="Group_4"
          opacity="0.28"
        >
          <path
            id="Vector_30"
            opacity="0.28"
            d="M341.21 256.346L339.401 276.543H392.426L390.618 256.346H341.21Z"
            fill="#171819"
          />
          <path
            id="Vector_31"
            opacity="0.28"
            d="M392.427 278.543H339.4C339.122 278.543 338.848 278.485 338.593 278.373C338.339 278.261 338.111 278.097 337.924 277.892C337.736 277.687 337.594 277.446 337.505 277.183C337.416 276.919 337.383 276.641 337.408 276.364L339.217 256.164C339.262 255.667 339.491 255.204 339.86 254.867C340.228 254.53 340.71 254.343 341.209 254.343H390.617C391.116 254.343 391.598 254.53 391.966 254.867C392.335 255.204 392.564 255.667 392.609 256.164L394.418 276.364C394.443 276.641 394.41 276.919 394.321 277.183C394.232 277.446 394.09 277.687 393.902 277.892C393.715 278.097 393.487 278.261 393.233 278.373C392.978 278.485 392.704 278.543 392.426 278.543H392.427ZM341.588 274.543H390.239L388.789 258.343H343.039L341.588 274.543Z"
            fill="#171819"
          />
        </g>
        <path
          id="Vector_32"
          d="M406.417 313.347H325.41C325.127 313.347 324.847 313.287 324.589 313.171C324.331 313.054 324.101 312.885 323.913 312.673C323.725 312.461 323.585 312.212 323.501 311.941C323.417 311.671 323.391 311.386 323.425 311.105C323.49 310.568 323.752 310.074 324.16 309.72C331.295 303.942 335.572 296.874 336.204 289.82L339.218 256.169C339.263 255.672 339.492 255.209 339.861 254.872C340.229 254.535 340.711 254.348 341.21 254.348H390.617C391.117 254.348 391.598 254.535 391.966 254.872C392.335 255.209 392.564 255.672 392.609 256.169L395.624 289.82C396.255 296.874 400.532 303.941 407.667 309.72C408.07 310.046 408.331 310.517 408.394 311.032C408.428 311.318 408.403 311.607 408.32 311.883C408.236 312.158 408.097 312.413 407.91 312.632C407.725 312.849 407.497 313.025 407.24 313.148C406.982 313.272 406.702 313.339 406.417 313.347ZM330.523 309.347H401.3C395.626 303.582 392.237 296.912 391.635 290.176L388.783 258.347H343.039L340.188 290.177C339.585 296.913 336.2 303.583 330.523 309.347Z"
          fill="#171819"
        />
        <path
          id="Vector_33"
          d="M523.914 31.346H207.914C201.287 31.346 195.914 36.7186 195.914 43.346V249.346C195.914 255.973 201.287 261.346 207.914 261.346H523.914C530.541 261.346 535.914 255.973 535.914 249.346V43.346C535.914 36.7186 530.541 31.346 523.914 31.346Z"
          fill="white"
        />
        <path
          id="Vector_34"
          d="M523.914 31.346H207.914C201.287 31.346 195.914 36.7186 195.914 43.346V249.346C195.914 255.973 201.287 261.346 207.914 261.346H523.914C530.541 261.346 535.914 255.973 535.914 249.346V43.346C535.914 36.7186 530.541 31.346 523.914 31.346Z"
          fill="#BFCFE2"
        />
        <path
          id="Vector_35"
          d="M523.914 263.347H207.914C204.202 263.343 200.644 261.866 198.019 259.242C195.395 256.617 193.918 253.059 193.914 249.347V43.347C193.918 39.6352 195.394 36.0766 198.019 33.4519C200.644 30.8273 204.202 29.351 207.914 29.347H523.914C527.626 29.351 531.184 30.8273 533.809 33.4519C536.434 36.0766 537.91 39.6352 537.914 43.347V249.347C537.91 253.059 536.433 256.617 533.809 259.242C531.184 261.866 527.626 263.343 523.914 263.347ZM207.914 33.347C205.263 33.3499 202.721 34.4044 200.846 36.2791C198.971 38.1539 197.917 40.6957 197.914 43.347V249.347C197.917 251.998 198.971 254.54 200.846 256.415C202.721 258.29 205.263 259.344 207.914 259.347H523.914C526.565 259.344 529.107 258.289 530.982 256.415C532.856 254.54 533.911 251.998 533.914 249.347V43.347C533.911 40.6957 532.857 38.1539 530.982 36.2791C529.107 34.4044 526.565 33.3499 523.914 33.347H207.914Z"
          fill="#171819"
        />
        <path
          id="Vector_36"
          d="M518.914 222.937V48.346L212.914 48.346V222.937L518.914 222.937Z"
          fill="#F6F9FD"
        />
        <g
          id="Group_5"
          opacity="0.28"
        >
          <path
            id="Vector_37"
            opacity="0.28"
            d="M223.928 31.346V169.939C223.928 172.061 224.771 174.096 226.271 175.596C227.771 177.096 229.806 177.939 231.928 177.939H421C423.122 177.939 425.157 177.096 426.657 175.596C428.157 174.096 429 172.061 429 169.939V31.346H223.928Z"
            fill="#171819"
          />
          <path
            id="Vector_38"
            opacity="0.28"
            d="M421 179.939H231.928C229.277 179.936 226.735 178.882 224.86 177.007C222.985 175.132 221.931 172.59 221.928 169.939V31.346C221.928 30.8156 222.139 30.3069 222.514 29.9318C222.889 29.5567 223.398 29.346 223.928 29.346H429C429.53 29.346 430.039 29.5567 430.414 29.9318C430.789 30.3069 431 30.8156 431 31.346V169.939C430.997 172.59 429.943 175.132 428.068 177.007C426.193 178.882 423.651 179.936 421 179.939ZM225.928 33.346V169.939C225.93 171.53 226.563 173.055 227.687 174.18C228.812 175.304 230.337 175.937 231.928 175.939H421C422.591 175.937 424.116 175.305 425.241 174.18C426.366 173.055 426.998 171.53 427 169.939V33.346H225.928Z"
            fill="#171819"
          />
        </g>
        <path
          id="Vector_39"
          d="M518.914 224.938H212.914C212.384 224.938 211.875 224.727 211.5 224.352C211.125 223.977 210.914 223.468 210.914 222.938V48.346C210.914 47.8156 211.125 47.3069 211.5 46.9318C211.875 46.5567 212.384 46.346 212.914 46.346H518.914C519.444 46.346 519.953 46.5567 520.328 46.9318C520.703 47.3069 520.914 47.8156 520.914 48.346V222.938C520.914 223.468 520.703 223.977 520.328 224.352C519.953 224.727 519.444 224.938 518.914 224.938ZM214.914 220.938H516.914V50.346H214.914V220.938Z"
          fill="#171819"
        />
        <path
          id="Vector_40"
          d="M379.359 244.048H352.468C351.938 244.048 351.429 243.837 351.054 243.462C350.679 243.087 350.468 242.578 350.468 242.048C350.468 241.518 350.679 241.009 351.054 240.634C351.429 240.259 351.938 240.048 352.468 240.048H379.359C379.889 240.048 380.398 240.259 380.773 240.634C381.148 241.009 381.359 241.518 381.359 242.048C381.359 242.578 381.148 243.087 380.773 243.462C380.398 243.837 379.889 244.048 379.359 244.048Z"
          fill="#171819"
        />
        <path
          id="Vector_41"
          d="M430.001 2.65399H240.928C236.51 2.65399 232.928 6.23571 232.928 10.654V158.94C232.928 163.358 236.51 166.94 240.928 166.94H430.001C434.419 166.94 438.001 163.358 438.001 158.94V10.654C438.001 6.23571 434.419 2.65399 430.001 2.65399Z"
          fill="#1D425E"
        />
        <path
          id="Vector_42"
          d="M438 24.108H232.928C232.398 24.108 231.889 23.8973 231.514 23.5222C231.139 23.1471 230.928 22.6384 230.928 22.108V10.654C230.931 8.00272 231.985 5.46088 233.86 3.58615C235.735 1.71142 238.277 0.656903 240.928 0.653992H430C432.651 0.656903 435.193 1.71142 437.068 3.58615C438.943 5.46088 439.997 8.00272 440 10.654V22.108C440 22.6384 439.789 23.1471 439.414 23.5222C439.039 23.8973 438.53 24.108 438 24.108ZM234.927 20.108H436V10.654C435.998 9.06318 435.366 7.53799 434.241 6.41312C433.116 5.28824 431.591 4.65558 430 4.65399H240.928C239.337 4.65584 237.812 5.28857 236.687 6.41339C235.563 7.53821 234.93 9.06326 234.928 10.654L234.927 20.108Z"
          fill="#171819"
        />
        <path
          id="Vector_43"
          d="M240.928 2.65399H430C432.122 2.65399 434.157 3.49684 435.657 4.99713C437.157 6.49742 438 8.53226 438 10.654V25.918H232.928V10.654C232.928 8.53226 233.771 6.49742 235.271 4.99713C236.771 3.49684 238.806 2.65399 240.928 2.65399Z"
          fill="#D0DDF0"
        />
        <path
          id="Vector_44"
          d="M438 27.918H232.928C232.398 27.918 231.889 27.7073 231.514 27.3322C231.139 26.9571 230.928 26.4484 230.928 25.918V10.654C230.931 8.00272 231.985 5.46088 233.86 3.58615C235.735 1.71142 238.277 0.656903 240.928 0.653992H430C432.651 0.656903 435.193 1.71142 437.068 3.58615C438.943 5.46088 439.997 8.00272 440 10.654V25.918C440 26.4484 439.789 26.9571 439.414 27.3322C439.039 27.7073 438.53 27.918 438 27.918ZM234.927 23.918H436V10.654C435.998 9.06318 435.366 7.53799 434.241 6.41312C433.116 5.28824 431.591 4.65558 430 4.65399H240.928C239.337 4.65584 237.812 5.28857 236.687 6.41339C235.563 7.53821 234.93 9.06326 234.928 10.654L234.927 23.918Z"
          fill="#171819"
        />
        <path
          id="Vector_45"
          d="M438 27.918H232.928C232.398 27.918 231.889 27.7073 231.514 27.3322C231.139 26.9571 230.928 26.4484 230.928 25.918V10.654C230.931 8.00272 231.985 5.46088 233.86 3.58615C235.735 1.71142 238.277 0.656903 240.928 0.653992H430C432.651 0.656903 435.193 1.71142 437.068 3.58615C438.943 5.46088 439.997 8.00272 440 10.654V25.918C440 26.4484 439.789 26.9571 439.414 27.3322C439.039 27.7073 438.53 27.918 438 27.918ZM234.927 23.918H436V10.654C435.998 9.06318 435.366 7.53799 434.241 6.41312C433.116 5.28824 431.591 4.65558 430 4.65399H240.928C239.337 4.65584 237.812 5.28857 236.687 6.41339C235.563 7.53821 234.93 9.06326 234.928 10.654L234.927 23.918Z"
          fill="#171819"
        />
        <path
          id="Vector_46"
          d="M430 168.939H240.928C238.277 168.936 235.735 167.882 233.86 166.007C231.985 164.132 230.931 161.59 230.928 158.939V10.654C230.931 8.00272 231.985 5.46088 233.86 3.58615C235.735 1.71142 238.277 0.656903 240.928 0.653992H430C432.651 0.656903 435.193 1.71142 437.068 3.58615C438.943 5.46088 439.997 8.00272 440 10.654V158.939C439.997 161.59 438.943 164.132 437.068 166.007C435.193 167.882 432.651 168.936 430 168.939ZM240.928 4.65399C239.337 4.65584 237.812 5.28857 236.687 6.41339C235.563 7.53821 234.93 9.06326 234.928 10.654V158.939C234.93 160.53 235.563 162.055 236.687 163.18C237.812 164.304 239.337 164.937 240.928 164.939H430C431.591 164.937 433.116 164.305 434.241 163.18C435.366 162.055 435.998 160.53 436 158.939V10.654C435.998 9.06318 435.366 7.53799 434.241 6.41312C433.116 5.28824 431.591 4.65558 430 4.65399H240.928Z"
          fill="#171819"
        />
        <g
          id="Group_6"
          opacity="0.28"
        >
          <path
            id="Vector_47"
            opacity="0.28"
            d="M528.689 63.4H412.673C408.255 63.4 404.673 66.9817 404.673 71.4V194.345C404.673 198.763 408.255 202.345 412.673 202.345H528.689C533.107 202.345 536.689 198.763 536.689 194.345V71.4C536.689 66.9817 533.107 63.4 528.689 63.4Z"
            fill="#171819"
          />
          <path
            id="Vector_48"
            opacity="0.28"
            d="M528.689 204.346H412.673C410.022 204.343 407.48 203.288 405.605 201.414C403.731 199.539 402.676 196.997 402.673 194.346V71.4C402.676 68.7487 403.73 66.2069 405.605 64.3322C407.48 62.4574 410.022 61.4029 412.673 61.4H528.689C531.34 61.4029 533.882 62.4574 535.757 64.3322C537.632 66.2069 538.686 68.7487 538.689 71.4V194.346C538.686 196.997 537.631 199.539 535.757 201.414C533.882 203.288 531.34 204.343 528.689 204.346ZM412.673 65.4C411.082 65.4016 409.557 66.0342 408.432 67.1591C407.307 68.284 406.675 69.8092 406.673 71.4V194.346C406.675 195.937 407.308 197.462 408.432 198.587C409.557 199.711 411.082 200.344 412.673 200.346H528.689C530.28 200.344 531.805 199.711 532.93 198.587C534.054 197.462 534.687 195.937 534.689 194.346V71.4C534.687 69.8092 534.055 68.284 532.93 67.1591C531.805 66.0342 530.28 65.4016 528.689 65.4H412.673Z"
            fill="#171819"
          />
        </g>
        <path
          id="Vector_49"
          d="M536.689 56.4H420.673C416.255 56.4 412.673 59.9817 412.673 64.4V187.345C412.673 191.763 416.255 195.345 420.673 195.345H536.689C541.107 195.345 544.689 191.763 544.689 187.345V64.4C544.689 59.9817 541.107 56.4 536.689 56.4Z"
          fill="#2AA1FF"
        />
        <path
          id="Vector_50"
          d="M536.689 197.346H420.673C418.022 197.343 415.48 196.288 413.605 194.414C411.731 192.539 410.676 189.997 410.673 187.346V64.4C410.676 61.7487 411.73 59.2069 413.605 57.3322C415.48 55.4574 418.022 54.4029 420.673 54.4H536.689C539.34 54.4029 541.882 55.4574 543.757 57.3322C545.632 59.2069 546.686 61.7487 546.689 64.4V187.346C546.686 189.997 545.631 192.539 543.757 194.414C541.882 196.288 539.34 197.343 536.689 197.346ZM420.673 58.4C419.082 58.4016 417.557 59.0342 416.432 60.1591C415.307 61.284 414.675 62.8092 414.673 64.4V187.346C414.675 188.937 415.308 190.462 416.432 191.587C417.557 192.711 419.082 193.344 420.673 193.346H536.689C538.28 193.344 539.805 192.711 540.93 191.587C542.054 190.462 542.687 188.937 542.689 187.346V64.4C542.687 62.8092 542.055 61.284 540.93 60.1591C539.805 59.0342 538.28 58.4016 536.689 58.4H420.673Z"
          fill="#171819"
        />
        <g
          id="Vector_51"
          opacity="0.34"
        >
          <path
            d="M519.426 76.339H437.857C435.003 76.339 432.69 78.6524 432.69 81.506V89.306C432.69 92.1597 435.003 94.473 437.857 94.473H519.426C522.28 94.473 524.593 92.1597 524.593 89.306V81.506C524.593 78.6524 522.28 76.339 519.426 76.339Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g
          id="Group_7"
          opacity="0.34"
        >
          <g
            id="Vector_52"
            opacity="0.34"
          >
            <path
              d="M483.58 105.646H437.937C435.083 105.646 432.77 107.959 432.77 110.813V118.613C432.77 121.467 435.083 123.78 437.937 123.78H483.58C486.434 123.78 488.747 121.467 488.747 118.613V110.813C488.747 107.959 486.434 105.646 483.58 105.646Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g
            id="Vector_53"
            opacity="0.34"
          >
            <path
              d="M519.506 105.646H501.851C498.997 105.646 496.684 107.959 496.684 110.813V118.613C496.684 121.467 498.997 123.78 501.851 123.78H519.506C522.36 123.78 524.673 121.467 524.673 118.613V110.813C524.673 107.959 522.36 105.646 519.506 105.646Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <path
          id="Vector_54"
          d="M253 18.286C255.209 18.286 257 16.4951 257 14.286C257 12.0769 255.209 10.286 253 10.286C250.791 10.286 249 12.0769 249 14.286C249 16.4951 250.791 18.286 253 18.286Z"
          fill="#171819"
        />
        <path
          id="Vector_55"
          d="M266.886 18.286C269.095 18.286 270.886 16.4951 270.886 14.286C270.886 12.0769 269.095 10.286 266.886 10.286C264.677 10.286 262.886 12.0769 262.886 14.286C262.886 16.4951 264.677 18.286 266.886 18.286Z"
          fill="#171819"
        />
        <path
          id="Vector_56"
          d="M280.772 18.286C282.981 18.286 284.772 16.4951 284.772 14.286C284.772 12.0769 282.981 10.286 280.772 10.286C278.563 10.286 276.772 12.0769 276.772 14.286C276.772 16.4951 278.563 18.286 280.772 18.286Z"
          fill="#171819"
        />
        <path
          id="Vector_57"
          d="M270.359 291.522H54.9C54.3696 291.522 53.8609 291.311 53.4858 290.936C53.1107 290.561 52.9 290.052 52.9 289.522C52.9 288.992 53.1107 288.483 53.4858 288.108C53.8609 287.733 54.3696 287.522 54.9 287.522H270.359C270.889 287.522 271.398 287.733 271.773 288.108C272.148 288.483 272.359 288.992 272.359 289.522C272.359 290.052 272.148 290.561 271.773 290.936C271.398 291.311 270.889 291.522 270.359 291.522Z"
          fill="#171819"
        />
        <path
          id="Vector_58"
          d="M270.359 299.539H54.9C54.3696 299.539 53.8609 299.328 53.4858 298.953C53.1107 298.578 52.9 298.069 52.9 297.539C52.9 297.009 53.1107 296.5 53.4858 296.125C53.8609 295.75 54.3696 295.539 54.9 295.539H270.359C270.889 295.539 271.398 295.75 271.773 296.125C272.148 296.5 272.359 297.009 272.359 297.539C272.359 298.069 272.148 298.578 271.773 298.953C271.398 299.328 270.889 299.539 270.359 299.539Z"
          fill="#171819"
        />
        <path
          id="Vector_59"
          d="M272.39 307.3H52.442C52.1159 307.3 51.7947 307.22 51.5065 307.068C51.2182 306.915 50.9717 306.694 50.7883 306.425C50.6049 306.155 50.4902 305.845 50.4543 305.52C50.4183 305.196 50.4622 304.868 50.582 304.565C53.1033 297.707 53.1033 290.176 50.582 283.318C50.4624 283.015 50.4187 282.687 50.4547 282.363C50.4908 282.039 50.6055 281.729 50.7888 281.459C50.9722 281.19 51.2187 280.969 51.5068 280.816C51.795 280.664 52.116 280.584 52.442 280.584H272.39C272.716 280.584 273.037 280.664 273.325 280.816C273.613 280.969 273.859 281.189 274.043 281.459C274.226 281.728 274.341 282.038 274.377 282.362C274.413 282.686 274.369 283.014 274.25 283.317C271.729 290.176 271.729 297.707 274.25 304.566C274.37 304.869 274.413 305.197 274.377 305.521C274.341 305.845 274.227 306.155 274.043 306.425C273.86 306.695 273.613 306.915 273.325 307.068C273.037 307.22 272.716 307.3 272.39 307.3ZM55.222 303.3H269.611C267.942 297.173 267.942 290.711 269.611 284.584H55.222C56.89 290.711 56.89 297.173 55.222 303.3Z"
          fill="#171819"
        />
        <path
          id="Vector_60"
          d="M601.07 311.573H561.809L555.424 251.39H607.455L601.07 311.573Z"
          fill="#6567C3"
        />
        <g
          id="Vector_61"
        >
          <path
            d="M555.424 251.39L555.541 252.495H595.183L588.915 311.573H601.07L607.455 251.39H555.424Z"
            fill="#6567C3"
          />
        </g>
        <path
          id="Vector_62"
          d="M601.069 313.573H561.809C561.315 313.573 560.838 313.391 560.471 313.06C560.103 312.73 559.871 312.275 559.819 311.784L553.435 251.6C553.405 251.321 553.435 251.039 553.521 250.772C553.608 250.506 553.749 250.26 553.937 250.051C554.125 249.843 554.354 249.676 554.61 249.562C554.866 249.448 555.144 249.389 555.424 249.389H607.455C607.735 249.389 608.013 249.448 608.269 249.562C608.525 249.676 608.754 249.843 608.942 250.051C609.13 250.26 609.271 250.506 609.358 250.772C609.444 251.039 609.474 251.321 609.444 251.6L603.059 311.783C603.007 312.275 602.775 312.729 602.408 313.06C602.04 313.39 601.563 313.573 601.069 313.573ZM563.607 309.573H599.271L605.231 253.39H557.647L563.607 309.573Z"
          fill="#171819"
        />
      </g>
      <g
        id="blu-3"
        class="proficiency_blu-3"
      >
        <path
          id="Vector_63"
          d="M432.69 175.811H488.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="blu-2"
        class="proficiency_blu-2"
      >
        <path
          id="Vector_64"
          d="M432.69 165.021H524.593"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="blu-1"
        class="proficiency_blu-1"
      >
        <path
          id="Vector_65"
          d="M432.69 154.23H524.593"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-9"
        class="proficiency_code-9"
      >
        <path
          id="Vector_66"
          d="M282.772 142.938H325.83"
          stroke="#9169FF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_67"
          d="M257 142.938H270.885"
          stroke="#7BFFA0"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-8"
        class="proficiency_code-8"
      >
        <path
          id="Vector_68"
          d="M357.205 130.619H374.497"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_69"
          d="M304.279 130.619H347.848"
          stroke="#FFC47D"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_70"
          d="M266.886 130.619H294.63"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-7"
        class="proficiency_code-7"
      >
        <path
          id="Vector_71"
          d="M317.893 118.299H333.037"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_72"
          d="M279.769 118.299H307.513"
          stroke="#9169FF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-6"
        class="proficiency_code-6"
      >
        <path
          id="Vector_73"
          d="M342.182 105.979H359.525"
          stroke="#9169FF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_74"
          d="M305.288 105.979H333.037"
          stroke="#FF5757"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_75"
          d="M279.769 105.979H295.513"
          stroke="#7BFFA0"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-5"
        class="proficiency_code-5"
      >
        <path
          id="Vector_76"
          d="M305.288 93.659H337.182"
          stroke="#FFC47D"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_77"
          d="M266.886 93.659H295.513"
          stroke="#FF5757"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-4"
        class="proficiency_code-4"
      >
        <g id="Group 2">
          <path
            id="Vector_78"
            d="M313.009 81.339H317.947"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_79"
            d="M293.189 81.339H304.279"
            stroke="#9169FF"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_80"
            d="M266.886 81.339H284.772"
            stroke="#7BFFA0"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <g
        id="code-3"
        class="proficiency_code-3"
      >
        <path
          id="Vector_81"
          d="M309.83 69.019H333.668"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_82"
          d="M279.769 69.019H302.251"
          stroke="#FF5757"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-2"
        class="proficiency_code-2"
      >
        <path
          id="Vector_83"
          d="M341.149 56.699H349.164"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_84"
          d="M304.279 56.699H331.506"
          stroke="#9169FF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_85"
          d="M266.886 56.699H294.63"
          stroke="#FFC47D"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g
        id="code-1"
        class="proficiency_code-1"
      >
        <path
          id="Vector_86"
          d="M350.741 44.379H363.669"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_87"
          d="M284.772 44.379H341.148"
          stroke="#7BFFA0"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_88"
          d="M257 44.379H276.772"
          stroke="#9169FF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </v-row>
</template>

<style lang="scss" scoped>
$totalCodeAnim: 5s;
$totalPenAnim: 8s;
$totalBluAnim: 7s;
$delayCode: 0s;
$delayPen: 0s;
$delayBlu: 0s;

.proficiency {
  &_code-1 {
    animation: code1 $totalCodeAnim $delayCode infinite;
  }
  &_code-2 {
    animation: code2 $totalCodeAnim $delayCode infinite;
  }
  &_code-3 {
    animation: code3 $totalCodeAnim $delayCode infinite;
  }
  &_code-4 {
    animation: code4 $totalCodeAnim $delayCode infinite;
  }
  &_code-5 {
    animation: code5 $totalCodeAnim $delayCode infinite;
  }
  &_code-6 {
    animation: code6 $totalCodeAnim $delayCode infinite;
  }
  &_code-7 {
    animation: code7 $totalCodeAnim $delayCode infinite;
  }
  &_code-8 {
    animation: code8 $totalCodeAnim $delayCode infinite;
  }
  &_code-9 {
    animation: code9 $totalCodeAnim $delayCode infinite;
  }
  &_red-pen {
    animation: red-pen $totalPenAnim $delayPen infinite;
  }
  &_yellow-pen {
    animation: yellow-pen $totalPenAnim $delayPen infinite;
  }
  &_blu-1 {
    animation: blu1 $totalBluAnim $delayBlu infinite;
  }
  &_blu-2 {
    animation: blu2 $totalBluAnim $delayBlu infinite;
  }
  &_blu-3 {
    animation: blu3 $totalBluAnim $delayBlu infinite;
  }
}

@keyframes code1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  84% {
    transform: translateY(0px);
    opacity: 1;
  }
  89% {
    transform: translateY(139px);
    opacity: 0;
  }
  100% {
    transform: translateY(139px);
    opacity: 0;
  }
}
@keyframes code2 {
  0% {
    opacity: 0;
  }
  7% {
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  82% {
    transform: translateY(0px);
    opacity: 1;
  }
  87% {
    transform: translateY(126px);
    opacity: 0;
  }
  100% {
    transform: translateY(126px);
    opacity: 0;
  }
}
@keyframes code3 {
  0% {
    opacity: 0;
  }
  9% {
    opacity: 0;
  }
  14% {
    opacity: 1;
  }
  80% {
    transform: translateY(0px);
    opacity: 1;
  }
  85% {
    transform: translateY(113px);
    opacity: 0;
  }
  100% {
    transform: translateY(113px);
    opacity: 0;
  }
}
@keyframes code4 {
  0% {
    opacity: 0;
  }
  11% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  78% {
    transform: translateY(0px);
    opacity: 1;
  }
  83% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}
@keyframes code5 {
  0% {
    opacity: 0;
  }
  13% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  76% {
    transform: translateY(0px);
    opacity: 1;
  }
  81% {
    transform: translateY(87px);
    opacity: 0;
  }
  100% {
    transform: translateY(87px);
    opacity: 0;
  }
}
@keyframes code6 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  74% {
    transform: translateY(0px);
    opacity: 1;
  }
  79% {
    transform: translateY(74px);
    opacity: 0;
  }
  100% {
    transform: translateY(74px);
    opacity: 0;
  }
}
@keyframes code7 {
  0% {
    opacity: 0;
  }
  17% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  72% {
    transform: translateY(0px);
    opacity: 1;
  }
  77% {
    transform: translateY(61px);
    opacity: 0;
  }
  100% {
    transform: translateY(61px);
    opacity: 0;
  }
}
@keyframes code8 {
  0% {
    opacity: 0;
  }
  19% {
    opacity: 0;
  }
  24% {
    opacity: 1;
  }
  70% {
    transform: translateY(0px);
    opacity: 1;
  }
  75% {
    transform: translateY(48px);
    opacity: 0;
  }
  100% {
    transform: translateY(48px);
    opacity: 0;
  }
}
@keyframes code9 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  26% {
    opacity: 1;
  }
  68% {
    transform: translateY(0px);
    opacity: 1;
  }
  73% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(25px);
    opacity: 0;
  }
}

@keyframes yellow-pen {
  0% {
    transform: translateY(-16px);
    opacity: 0;
  }
  5% {
    transform: translateY(-16px);
    opacity: 0;
  }
  10% {
    transform: translateY(0px);
    opacity: 1;
    }
  80% {
    transform: translateY(0px);
    opacity: 1;
    }
  85% {
    transform: translateY(20px);
    opacity: 0;
    }
  100% {
    transform: translateY(20px);
    opacity: 0;
    }
}
@keyframes red-pen {
  0% {
    transform: translateY(-16px);
    opacity: 0;
  }
  10% {
    transform: translateY(-16px);
    opacity: 0;
  }
  15% {
    transform: translateY(0px);
    opacity: 1;
    }
  85% {
    transform: translateY(0px);
    opacity: 1;
    }
  90% {
    transform: translateY(20px);
    opacity: 0;
    }
  100% {
    transform: translateY(20px);
    opacity: 0;
    }
}
@keyframes blu1 {
  0% {
    transform: translateX(-16px);
    opacity: 0;
  }
  5% {
    transform: translateX(-16px);
    opacity: 0;
  }
  15% {
    transform: translateX(0px);
    opacity: 1;
    }
  90% {
    transform: translateX(0px);
    opacity: 1;
    }
  95% {
    transform: translateX(20px);
    opacity: 0;
    }
  100% {
    transform: translateX(20px);
    opacity: 0;
    }
}
@keyframes blu2 {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  20% {
    transform: translateX(20px);
    opacity: 0;
  }
  30% {
    transform: translateX(0px);
    opacity: 1;
    }
  90% {
    transform: translateX(0px);
    opacity: 1;
    }
  95% {
    transform: translateX(-20px);
    opacity: 0;
    }
  100% {
    transform: translateX(-20px);
    opacity: 0;
    }
}
@keyframes blu3 {
  0% {
    transform: translateX(-16px);
    opacity: 0;
  }
  35% {
    transform: translateX(-16px);
    opacity: 0;
  }
  45% {
    transform: translateX(0px);
    opacity: 1;
    }
  90% {
    transform: translateX(0px);
    opacity: 1;
    }
  95% {
    transform: translateX(20px);
    opacity: 0;
    }
  100% {
    transform: translateX(20px);
    opacity: 0;
    }
}

#Vector_61 {
  mix-blend-mode: overlay;
}
#Vector_51, #Vector_52, #Group_7, #Vector_53 {
  mix-blend-mode: screen;
}
</style>
