<template>
  <Vue3Marquee
    :clone="true"
    :duration="8"
    :pause-on-hover="false"
    :gradient="true"
    :gradient-color="[255, 255, 255]"
    gradient-length="10%"
    class="pt-2"
  >
    <v-icon
      v-for="technology in technologies"
      :key="technology.name"
      class="marquee-icons"
    >
      {{ technology.icon }}
    </v-icon>
  </Vue3Marquee>
</template>

<script>
import {technologyData} from '@/pages/home/data/technologies';

export default {
  data: () => ({
    technologies: technologyData,
  }),
};
</script>

<style scoped>
.marquee-icons {
  margin-inline: 10px;
}
</style>
