import Proficiency from '@/models/proficiency';

const android: Proficiency = new Proficiency(
    'ANDROID',
    70,
);
const backend: Proficiency = new Proficiency(
    'BACK-END',
    90,
);
const database: Proficiency = new Proficiency(
    'DATABASE',
    75,
);
const frontend: Proficiency = new Proficiency(
    'FRONT-END',
    75,
);

const tools: Proficiency = new Proficiency(
    'TOOLS & IDE',
    100,
);

export const proficiencyData: Proficiency[] = [
  android,
  backend,
  database,
  frontend,
  tools,
];

export class ProficiencyEnum {
  static readonly ANDROID = android;
  static readonly BACKEND = backend;
  static readonly DATABASE = database;
  static readonly FRONTEND = frontend;
  static readonly TOOLS = tools;
}
