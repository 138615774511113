<template>
  <div class="home-wrapper">
    <LandingSection />

    <Wave v-if="$vuetify.display['xs']" :fill="'background'" class="bg-background2" />
    <div v-else class="gradient" />

    <AboutSection />

    <WaveLayeredSvg :fill="'background2'" class="bg-background" />

    <WorkSection />

    <Wave :fill="'background'" class="bg-background2" />

    <ProficiencySection />

    <WaveLayeredSvg :fill="'background2'" class="bg-background" />

    <ProjectSection />
  </div>
</template>

<script>
import LandingSection from '@/pages/home/LandingSection.vue';
import AboutSection from '@/pages/home/AboutSection.vue';
import WorkSection from '@/pages/home/WorkSection.vue';
import ProficiencySection from '@/pages/home/ProficiencySection.vue';
import ProjectSection from '@/pages/home/ProjectSection.vue';

import Wave from '@/pages/common/assets/svg/WaveSvg.vue';
import WaveLayeredSvg from '@/pages/common/assets/svg/WaveLayeredSvg.vue';

export default {
  name: 'HomeView',
  components: {
    LandingSection,
    AboutSection,
    WorkSection,
    ProficiencySection,
    ProjectSection,

    Wave,
    WaveLayeredSvg,
  },
};
</script>

<style scoped>
.home-wrapper {
  font-size: 18px;
  line-height: 1.7;
  z-index: 2;
}

/* https://www.learnui.design/tools/gradient-generator.html */
.gradient {
  height: 70px;
  background: linear-gradient(0deg, #ffffff, #fefefe, #fdfdfd, #fcfcfc, #fbfbfb, #fafafa, #fafafa, #f9f9f9, #f8f8f8, #f7f7f7, #f6f6f6, #f5f5f5);
}
</style>
