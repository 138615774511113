<template>
  <div id="app">
    <v-app>
      <Loader />
      <CustomCursor />
      <!-- // TODO refactor classi css con il doppio trattino -->
      <Navigation />
      <router-view />
      <FooterApp />
    </v-app>
  </div>
</template>

<script>
import Loader from '@/pages/common/Loader.vue';
import CustomCursor from '@/pages/common/Cursor.vue';
import Navigation from '@/pages/common/Navigation.vue';
import FooterApp from '@/pages/common/Footer.vue';

export default {
  name: 'App',
  components: {
    Loader,
    CustomCursor,
    Navigation,
    FooterApp,
  },
  mounted() {
    this.onResize();

    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.$store.dispatch('setWidth');
    },
  },
};
</script>

<style>
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
}
</style>
