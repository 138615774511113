export default function useNavigation() {
  function scrollTo(elementId: string): void {
    const element = document.getElementById(elementId)!!;

    if (!element && elementId != '') {
      console.warn('Cannot scroll to element with id: ' + elementId);
      return;
    }

    element.style.marginTop = '30px'; // Add the marginTop property to scroll a little bit more
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
    element.style.marginTop = '0'; // Remove the marginTop property after scrolling
  }

  return {scrollTo};
};
