<template>
  <section
    id="about"
    class="about bg-background2"
  >
    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: $vuetify.display[$displaySizeUp] ? 0.5 : 0
        }
      }"
      class="about-container g-section-container"
    >
      <v-row no-gutters>
        <v-col cols="12" class="slide-in-bottom-1" :class="{ 'entered': entered }">
          <SectionTitle :title="'home.about.title'" />
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <p class="proficiency-text text-textcolor slide-in-bottom-2 pb-5" :class="{ 'entered': entered }">
            {{ $t('home.about.description') }}
          </p>

          <p class="proficiency-text text-textcolor slide-in-bottom-3" :class="{ 'entered': entered }">
            {{ $t('home.about.description2') }}

            <b><span class="text-primary">@</span><a
              href="https://www.latek.it/"
              target="_blank"
              class="underline-effect"
            >Sorint.Tek</a></b>

            {{ $t('home.about.description3') }}
          </p>

          <v-row
            no-gutters
            align="end"
            class="scroll-about slide-in-bottom-4"
            :class="{ 'entered': entered }"
          >
            <ScrollBtn
              :go-to="'contact'"
              :custom-text="$t('home.about.resumeBtn')"
              :bg-color="'background'"
              :parent-class="'.scroll-about'"
              :disabled="true"
              class="mt-10"
            />
            <!-- // TODO -->
            <span class="test">
              *currently unavailable
            </span>
          </v-row>
        </v-col>

        <v-spacer />

        <v-col
          cols="12"
          lg="4"
          md="5"
          class="g-svg-section slide-in-bottom-5"
          :class="{'pt-13': $vuetify.display.smAndDown, 'entered': entered}"
          align-self="end"
        >
          <AboutSvg />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SectionTitle from '@/pages/home/components/SectionTitle.vue';
import AboutSvg from '@/pages/home/assets/svg/AboutSvg.vue';
import ScrollBtn from '@/pages/home/components/ScrollBtn.vue';
import {useIntersection} from '@/composables/intersection';

export default {
  name: 'AboutSection',
  components: {
    SectionTitle,
    AboutSvg,
    ScrollBtn,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    return {entered, onIntersect};
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$about-container-padding-top: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 90px,
);

$about-container-padding-bottom: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

.about-container {
  @include global.for-each-breakpoint('padding-top', $about-container-padding-top);
  @include global.for-each-breakpoint('padding-bottom', $about-container-padding-bottom);
}

// TODO
.test {
  font-size: 15px;
}
</style>
