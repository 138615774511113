import {MouseFollowerOptions} from '@/models/mouse-follower';

export const MouseFollowerOptionsData: MouseFollowerOptions = {
  hiddenState: '-hidden',
  textState: '-text',
  activeState: '-active',
  mediaState: '-media',
  stateDetection: {
    '-pointer': 'a,button',
  },
  visible: true,
  visibleOnState: false,
  speed: 0.55,
  ease: 'expo.out',
  overwrite: true,
  skewingText: 1.5,
  skewingMedia: 2,
  skewingDelta: 0.001,
  skewingDeltaMax: 0.15,
  stickDelta: 0.15,
  showTimeout: 0,
  hideOnLeave: true,
  hideTimeout: 300,
  hideMediaTimeout: 0,
};
