import {cursor} from '@/translations/en/cursor';
import {drawer} from '@/translations/en/drawer';
import {home} from '@/translations/en/home-page/index';
import {footer} from '@/translations/en/footer';

export const en = {
  cursor,
  drawer,
  home,
  footer,
};
