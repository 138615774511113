<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
      }
    }"
    class="container"
  >
    <div class="slide-in-bottom-3 pb-8" :class="{ 'entered': entered }">
      <WorksTimelineTabsContainer />
    </div>

    <div class="content slide-in-bottom-4" :class="{ 'entered': entered }">
      <template
        v-for="(work, index) in works"
        :key="index"
      >
        <WorksTimelineContent
          :tab-index="index + 1"
          :work="work"
        />
      </template>
    </div>
  </div>
</template>

<script>
import WorksData from '@/pages/home/data/works';
import WorksTimelineTabsContainer from '@/pages/home/components/WorksTimelineTabsContainer.vue';
import WorksTimelineContent from '@/pages/home/components/WorksTimelineContent.vue';
import {useIntersection} from '@/composables/intersection';

export default {
  name: 'WorksTimeline',
  components: {
    WorksTimelineTabsContainer,
    WorksTimelineContent,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    return {entered, onIntersect};
  },
  data: () => ({
    works: WorksData,
  }),
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.container {
  position: relative;
}

.content {
  position: relative;
}
</style>
