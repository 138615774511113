/* eslint-disable max-len */
export interface WorkInterface {
  title: string;
  tabTitle: string;
  company: string;
  companyLink: string;
  startTime: string;
  endTime: string;
  descriptionList: string[];
}

export class Work implements WorkInterface {
  title: string;
  tabTitle: string;
  company: string;
  companyLink: string;
  startTime: string;
  endTime: string;
  descriptionList: string[];

  constructor(title: string, tabTitle: string, company: string, companyLink: string, startTime: string, endTime: string, descriptionList: string[]) {
    this.title = title;
    this.tabTitle = tabTitle;
    this.company = company;
    this.companyLink = companyLink;
    this.startTime = startTime;
    this.endTime = endTime;
    this.descriptionList = descriptionList;
  }
}
