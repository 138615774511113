export const landing = {
  intro: 'Hi, my name is',
  introMobile: 'Hi, my name is',
  subtitle: 'I build things on the web',
  description: 'Maybe I don\'t have the designer\'s eye, but I\'m good at creating tailor-made code to bring ideas to life.',
  description2: 'I am also:',
  projectBtn: 'CHECK PROJECTS',
};

export const textRotator = {
  one: 'Full-stack developer',
  two: 'Software Engineer',
  three: 'IT Enthusiast',
};
