import {ActionContext} from 'vuex';

export interface ScreenResizeState {
  currentWidth: number;
}

const state: ScreenResizeState = {
  currentWidth: window.innerWidth,
};

export const ScreenResize = {
  namespaced: false,
  state,
  mutations: {
    setWidth(state: ScreenResizeState) {
      state.currentWidth = window.innerWidth;
    },
  },
  getters: {
    getWidth(state: ScreenResizeState): number {
      return state.currentWidth;
    },
  },
  actions: {
    setWidth(context: ActionContext<ScreenResizeState, any>) {
      context.commit('setWidth');
    },
  },
};
