<template>
  <a
    ref="rollingText"
    class="rolling-text"
    :href="link"
    target="_blank"
    @click="onClick"
  >
    <div
      v-for="block of [1, 2]"
      :key="block"
      class="block"
    >
      <div
        v-for="(letter, index) of innerText.trim()"
        :key="index"
        class="letter"
      >
        {{ letter.trim() === '' ? '\xa0' : letter }}
      </div>
    </div>
  </a>
</template>

<script>
import MagneticTextEffect from '@/pages/common/assets/ts/effect/magnefic-text';

export default {
  props: {
    innerText: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    magnetic: MagneticTextEffect.prototype,
    animEnabled: false,
    rollingText: HTMLElement,
  }),
  computed: {
    screenSizeChanged() {
      return this.$vuetify.display[this.$displaySizeUp];
    },
  },
  watch: {
    screenSizeChanged() {
      this.animEnabled = !this.animEnabled;
      this.toggleMagneticTextEffect();
    },
  },
  mounted() {
    requestAnimationFrame(() => {
      this.rollingText = this.$refs.rollingText;
      this.animEnabled = this.$vuetify.display[this.$displaySizeUp] ? true : false;

      this.rollingText.addEventListener('mouseover', () => {
        this.rollingText.classList.remove('play');
      });

      this.toggleMagneticTextEffect();
    });
  },
  methods: {
    toggleMagneticTextEffect() {
      // this.$refs.rollingText because SocialScrollText is not visible in mobile
      this.magnetic = this.animEnabled ? new MagneticTextEffect(this.$refs.rollingText) :
        (this.magnetic instanceof MagneticTextEffect) ? this.magnetic.destroy() : undefined;
    },
    onClick() {
      this.$gtag.event('social_button_click', {
        'event_category': 'Button',
        'event_label': 'Click',
        'value': '1',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$delay: 0.015s;
$duration: 0.5s;
$easing: cubic-bezier(0.76, 0, 0.24, 1);

.rolling-text {
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  line-height: 25px;
  height: 25px;
  text-decoration: none;
  overflow: hidden;
  color: rgb(var(--v-theme-primary));
  margin-left: -1px;

  @media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
    &:hover,
    &.play {
      .letter {
        transform: translateY(-100%);
      }
    }
  }

  .block:last-child {
    color: rgb(var(--v-theme-primary));
  }

  .letter {
    display: inline-block;
    transition: transform $duration $easing;
  }
}

@for $i from 0 through 20 {
  .letter:nth-child(#{$i + 1}) {
    transition-delay: $delay * $i;
  }
}
</style>
