<template>
  <div
    class="content__section"
    :class="{ 'visible': tabIndex.toString() === activeTab, 'hidden': tabIndex.toString() !== activeTab }"
    :data-tab="tabIndex"
  >
    <p class="title">
      <b>{{ $t(work.title) }}&nbsp;&nbsp;@<a
        :href="work.companyLink"
        target="_blank"
        class="underline-effect"
      >{{ work.company }}</a></b>
    </p>

    <p class="subtitle text-textcolor pb-5">
      <span>{{ $t(work.startTime) }}</span> - <span>{{ $t(work.endTime) }}</span>
    </p>

    <template
      v-for="(description, index) in work.descriptionList"
      :key="index"
    >
      <li class="text-textcolor text-start pb-5">
        {{ $t(description) }}
      </li>
    </template>
  </div>
</template>

<script>
import {Work} from '@/models/work';
import WorksData from '@/pages/home/data/works';

export default {
  name: 'WorksTimelineContent',
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    work: {
      type: Work,
      required: true,
    },
  },
  data: () => ({
    activeTab: '1',
    works: WorksData,
  }),
  computed: {
    tabChanged() {
      return this.$store.state.WorkTabManager.activeTab;
    },
  },
  watch: {
    tabChanged() {
      this.activeTab = this.$store.state.WorkTabManager.activeTab;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.content__section {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  transition: opacity 0s ease;
  overflow: hidden;

  &.visible {
    position: relative;
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease;
    z-index: 2;
  }

  &.hidden {
    display: none;
  }
}

.subtitle {
  padding-top: 2px;
  font-size: .8em;
}
</style>>
