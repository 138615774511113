/* eslint-disable max-len */
import {ProjectInterface} from '@/models/project';
import {PlatformEnum} from '@/pages/home/data/platforms';
import {TechnologyEnum} from '@/pages/home/data/technologies';

const descriptionTranslation = 'home.projects.listys.description';
const priceTranslation = 'home.projects.listys.price';

const LISTYS: ProjectInterface = {
  title: 'Listys',
  description: descriptionTranslation,
  logo: 'listysLogo.png',
  price: priceTranslation,
  link: 'https://play.google.com/store/apps/details?id=com.MarcoLanfranchi.ListaDellaSpesa',
  platforms: [PlatformEnum.ANDROID.name],
  technologies: [TechnologyEnum.FLUTTER.name, TechnologyEnum.DART.name, TechnologyEnum.FIREBASE.name],
};

export default LISTYS;
