<template>
  <v-card
    ref="card"
    class="mx-auto project-card"
    elevation="2"
    :href="project.link"
    target="_blank"
    :ripple="false"
    :hover="false"
    :disabled="true"
    @click="onclick"
    @mouseenter="setHoverEffect"
    @mouseleave="setHoverEffect"
  >
    <div class="card-content fill-height d-flex flex-column pa-4">
      <v-card-title class="card-header d-flex align-content-end pa-0 mb-7">
        <img width="40" :src="logo(project.logo)" class="project-img image-transition">

        <div class="card-icon ml-auto">
          <v-icon size="20">
            mdi-open-in-new
          </v-icon>
        </div>
      </v-card-title>

      <v-card-text class="card-title text-h6 pa-0 mb-2 flex-grow-0">
        <b>{{ project.title }}</b>
      </v-card-text>

      <!-- max 30 words -->
      <v-card-text class="card-description pa-0 mb-6 flex-grow-1">
        {{ $t(project.description) }}
      </v-card-text>

      <v-card-text class="card-technologies pa-0 flex-grow-0">
        {{ project.technologies.join(' - ') }}
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true,
    },
    parentClass: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    card: HTMLElement,
  }),
  mounted() {
    this.card = this.$refs.card.$el;
  },
  methods: {
    logo(name) {
      return require(`@/assets/img/projects/${name}`);
    },
    onclick() {
      console.log('Click');
    },
    setHoverEffect(event) {
      if (this.$vuetify.display[this.$displaySizeDown]) {
        return;
      }

      const parentEl = document.querySelector(this.parentClass);

      const transformValue = window.getComputedStyle(parentEl).getPropertyValue('transform');

      // EX: matrixValues = ['1', '0', '0', '1', '0', '-103.013']
      const matrixValues = transformValue.substring(7, transformValue.length - 1).split(', ');

      // Gets parentEl's Y-axis translation or sets 0 if none
      const parentElTranslateY = transformValue === 'none' ? 0 : parseFloat(matrixValues[5]);

      // Considers container margin as parentClass is the container
      const cardOffsetLeft = this.card.offsetLeft + parentEl.offsetLeft;
      // Considers parentEl's translation as btn offsetTop is offset from parentEl top
      const cardOffsetTop = this.card.offsetTop + parentEl.offsetTop + parentElTranslateY;

      const x = event.pageX - cardOffsetLeft;
      const y = event.pageY - cardOffsetTop;

      this.card.style.setProperty('--x', x + 'px');
      this.card.style.setProperty('--y', y + 'px');
    },
  },
};
</script>

<style>
.project-card > .v-card__overlay {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.project-card {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: rgba(var(--v-theme-background2), 1);
}

.card-technologies {
  transition: color .5s !important;
  font-weight: 300;
}

.card-header,
.card-icon,
.card-description,
.card-title {
  transition: color .5s !important;
}

@media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
  .project-card::before {
    content: "";
    display: block;
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    z-index: -1 !important;
    background-color: rgba(var(--v-theme-primary), 1) !important;
    transition: width 0.6s, height 0.6s;
  }

  .project-card:hover {
    &::before {
      width: 1200px;
      height: 1200px;
    }

    .card-title,
    .card-technologies,
    .card-icon {
      color: rgba(var(--v-theme-background2), 1);
      transition: color .5s;
    }

    .card-description {
      color: rgba(var(--v-theme-projecttext), 1);
      transition: color .5s;
    }
  }
}
</style>
