import NavigationRoute from '@/models/navigation-route';

const NavigationRoutesData: NavigationRoute[] = [
  new NavigationRoute(
      'drawer.about',
      'about',
  ),
  new NavigationRoute(
      'drawer.work',
      'work',
  ),
  new NavigationRoute(
      'drawer.proficiency',
      'proficiency',
  ),
  new NavigationRoute(
      'drawer.project',
      'project',
  ),
  new NavigationRoute(
      'drawer.contact',
      'footer',
  ),
];

export default NavigationRoutesData;
