import {ActionContext} from 'vuex';

export interface MenuState {
  isOpen: boolean;
}

const state: MenuState = {
  isOpen: false,
};

export const MenuManager = {
  namespaced: false,
  state,
  mutations: {
    toggle(state: MenuState) {
      state.isOpen = !state.isOpen;
    },
  },
  actions: {
    toggleMenu(context: ActionContext<MenuState, any>) {
      context.commit('toggle');
    },
  },
};
