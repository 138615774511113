import HomeView from '@/views/HomeView.vue';

export default [
  {
    // redirect to / if path is not handled
    path: '/:pathMatch(.*)*',
    redirect: '/en',
  },
  {
    path: '/',
    redirect: '/en',
  },
  {
    path: '/:lang?',
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView,
      },
    ],
  },
];
