<template>
  <div class="menu-box">
    <v-btn
      ref="btn"
      v-scroll="onScroll"
      color="transparent"
      class="menu-btn"
      :class="{'menu-btn-compact': scroll || $store.state.MenuManager.isOpen }"
      elevation="0"
      :ripple="false"
      icon
      @click="toggleMenu"
      @mouseenter="toggleHover"
      @mouseleave="toggleHover"
    >
      <v-icon
        ref="icon"
        size="50"
      >
        <HamburgerSvg
          :hover="hover"
          :is-open="$store.state.MenuManager.isOpen"
        />
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import StickyMenuEffect from '@/pages/common/assets/ts/effect/sticky-menu';
import HamburgerSvg from '@/pages/common/assets/svg/HamburgerSvg.vue';

export default {
  name: 'MenuBtn',
  components: {
    HamburgerSvg,
  },
  data: () => ({
    btn: HTMLElement,
    icon: HTMLElement,
    sticky: StickyMenuEffect.prototype,
    animEnabled: false,
    isMenuOpen: false,
    hover: false,
    scroll: false,
  }),
  computed: {
    screenSizeChanged() {
      return this.$vuetify.display[this.$displaySizeUp];
    },
  },
  watch: {
    screenSizeChanged() {
      this.animEnabled = !this.animEnabled;
      this.toggleStickyBtnEffect();
    },
  },
  mounted() {
    requestAnimationFrame(() => {
      this.btn = this.$refs.btn.$el;
      this.icon = this.$refs.icon.$el;
      this.animEnabled = this.$vuetify.display[this.$displaySizeUp] ? true : false;
      this.toggleStickyBtnEffect();
    });
  },
  methods: {
    toggleStickyBtnEffect() {
      this.sticky = this.animEnabled ? new StickyMenuEffect(this.btn, this.icon) :
        (this.sticky instanceof StickyMenuEffect) ? this.sticky.destroy() : undefined;
    },
    toggleMenu() {
      this.$store.dispatch('toggleMenu');
    },
    toggleHover() {
      this.hover = !this.hover;
    },
    onScroll(e) {
      const top = window?.scrollY || e.target.scrollTop || 0;
      this.scroll = top > 0;
    },
  },
};
</script>

<style>
.menu-btn > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

// Top is same as nav-top padding -14px
$menu-btn-top: (
  'xxl': 40px,
  'xl': 40px,
  'lg': 35px,
  'md': 35px,
  'sm': 30px,
  'xs': 19px,
);

$menu-btn-hover-before: (
  'xxl': -100px,
  'xl': -100px,
  'lg': -100px,
);

.menu-box {
  z-index: 3000;
  @include global.for-each-breakpoint('top', $menu-btn-top);
}

.menu-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  bottom: 10px;
  right: 0px;
  left: -65px;

  @media #{map-get(vuetify.$display-breakpoints, 'xl-and-up')} {
    left: -70px;
  }

  @media #{map-get(vuetify.$display-breakpoints, 'xs')} {
    left: -60px;
  }
}

@media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
  .menu-box:hover
  .menu-btn::before {
    @include global.for-each-breakpoint('top', $menu-btn-hover-before);
    @include global.for-each-breakpoint('bottom', $menu-btn-hover-before);
    @include global.for-each-breakpoint('right', $menu-btn-hover-before);
    @include global.for-each-breakpoint('left', $menu-btn-hover-before);
  }
}

// Removes menu-text from menu-btn box when page is not on top or menu is open
.menu-btn-compact::before {
  left: 0px;
}
</style>
