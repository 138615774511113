<template>
  <div
    v-scroll="onScroll"
    :class="{ 'marquee-invisible': !fab }"
    class="marquee-container"
  >
    <Vue3Marquee
      :clone="true"
      :duration="15"
      :pause-on-hover="false"
      :gradient="true"
      :gradient-color="[245, 245, 245]"
      gradient-length="5%"
    >
      <span
        v-for="(word, index) in helloArray"
        :key="index"
        class="marquee-text"
      >
        {{ word }}
      </span>
    </Vue3Marquee>
  </div>
</template>

<script>
export default {
  data: () => ({
    fab: true,
    helloArray: [
      'Ciao', // Italiano
      'こんにちは', // Giapponese
      'Hello', // Inglese
      'नमस्ते', // Hindi
      'Olá', // Portoghese
      'مرحبا', // Arabo
      'Bonjour', // Francese
      '你好', // Cinese
      'Hola', // Spagnolo
      '안녕하세요', // Coreano
      'Hallo', // Tedesco
      'Здравствуйте', // Russo
    ],
  }),
  methods: {
    onScroll(e) {
      const top = window?.scrollY || e.target.scrollTop || 0;
      const offsetTop = 0;
      this.fab = top <= offsetTop;
    },
  },
};
</script>

<style scoped>
.marquee-text {
  margin-inline: 10px;
}
</style>

<style lang="scss" scoped>
.marquee-container {
  transition: opacity 0.3s ease;
}

.marquee-invisible {
  opacity: 0;
}
</style>
