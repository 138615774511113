import {createStore, useStore as baseUseStore, Store} from 'vuex';
import {InjectionKey} from 'vue';

// Modules
import {ScreenResize, ScreenResizeState} from '@/store/modules/screen-resize';
import {MenuManager, MenuState} from '@/store/modules/menu-manager';
import {WorkTabManager, WorkTabState} from './modules/work-tab-manager';
import {LoaderManager, LoaderState} from './modules/loader-manager';

// State fields must match module names (case sensitive is also required)
export interface State {
  ScreenResize: ScreenResizeState
  MenuManager: MenuState
  WorkTabManager: WorkTabState
  LoaderManager: LoaderState
}

// https://vuex.vuejs.org/guide/typescript-support.html#typing-store-property-in-vue-component
export const key: InjectionKey<Store<State>> = Symbol();

// Retrieve the typed store when useStore()
export function useStore(): Store<State> {
  return baseUseStore(key);
}

export const store = createStore<State>({
  modules: {
    ScreenResize,
    MenuManager,
    WorkTabManager,
    LoaderManager,
  },
});
