<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <g clip-path="url(#clip0_1_2)">
        <path
          d="M56.7942 18.7672C56.7938 17.6939 56.5642 16.7454 56.1 15.9314C55.644 15.131 54.9611 14.4601 54.0451 13.9296C46.4838 9.56986 38.9152 5.22342 31.3564 0.859195C29.3186 -0.31725 27.3428 -0.274361 25.3201 0.918972C22.3104 2.69386 7.24236 11.3265 2.75228 13.9272C0.903129 14.9976 0.0033334 16.6359 0.00288895 18.7652C0 27.533 0.00288895 36.3005 0 45.0685C0 46.1183 0.220227 47.0483 0.664013 47.8501C1.12024 48.6748 1.8127 49.3641 2.74983 49.9065C7.24014 52.5072 22.3102 61.1392 25.3192 62.9145C27.3428 64.1085 29.3186 64.1512 31.3571 62.9743C38.9161 58.6099 46.4851 54.2636 54.0475 49.9039C54.9846 49.3616 55.6771 48.6719 56.1333 47.8479C56.5764 47.0461 56.7971 46.1161 56.7971 45.0661C56.7971 45.0661 56.7971 27.5352 56.7942 18.7672Z"
          fill="#A179DC"
        />
        <path
          d="M28.4855 31.8314L0.664017 47.8501C1.12025 48.6748 1.81271 49.3641 2.74984 49.9065C7.24014 52.5072 22.3102 61.1392 25.3192 62.9145C27.3428 64.1085 29.3186 64.1512 31.3571 62.9743C38.9161 58.6099 46.4851 54.2636 54.0475 49.9039C54.9846 49.3616 55.6771 48.6719 56.1333 47.8479L28.4855 31.8314Z"
          fill="#280068"
        />
        <path
          d="M56.7942 18.7672C56.7938 17.6939 56.5642 16.7454 56.1 15.9314L28.4854 31.8314L56.1333 47.8479C56.5764 47.0461 56.7967 46.1161 56.7971 45.0661C56.7971 45.0661 56.7971 27.5352 56.7942 18.7672Z"
          fill="#390091"
        />
        <path
          d="M44.8658 25.8431V28.8373H47.86V25.8431H49.3571V28.8373H52.3514V30.3343H49.3571V33.3285H52.3514V34.8256H49.3571V37.8197H47.86V34.8256H44.8658V37.8197H43.3687V34.8256H40.3745V33.3285H43.3687V30.3343H40.3745V28.8373H43.3687V25.8431H44.8658ZM47.86 30.3343H44.8658V33.3285H47.86V30.3343Z"
          fill="white"
        />
        <path
          d="M28.5465 10.8058C36.3564 10.8058 43.175 15.0472 46.8273 21.3515L46.7916 21.2909L37.6029 26.5816C35.7925 23.5164 32.4736 21.4478 28.6664 21.4052L28.5465 21.4045C22.7407 21.4045 18.0338 26.1111 18.0338 31.9167C18.0338 33.8153 18.54 35.595 19.4203 37.1322C21.2324 40.2956 24.6388 42.4292 28.5465 42.4292C32.4783 42.4292 35.9042 40.2685 37.707 37.0712L37.6632 37.148L46.8382 42.4631C43.226 48.7141 36.5008 52.9424 28.7817 53.0267L28.5465 53.028C20.712 53.028 13.8739 48.7605 10.2309 42.4227C8.45245 39.3287 7.43486 35.7418 7.43486 31.9167C7.43486 20.2576 16.8867 10.8058 28.5465 10.8058Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect
            width="56.89"
            height="64"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </v-row>
</template>
