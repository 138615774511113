export const landing = {
  intro: 'Ciao, mi chiamo',
  introMobile: 'Ciao, mi chiamo',
  subtitle: 'Costruisco cose sul web',
  description: 'Forse non ho l\'occhio del designer, ma sono bravo a creare codice su misura per rendere reali le idee.',
  description2: 'Sono anche:',
  projectBtn: 'I MIEI PROGETTI',
};

export const textRotator = {
  one: 'Sviluppatore full-stack',
  two: 'Ingegnere del software',
  three: 'Appassionato IT',
};
