<template>
  <v-btn
    ref="tab"
    class="tab"
    :ripple="false"
    elevation="0"
    :class="{ 'active': tabIndex.toString() === activeTab }"
    @click="changeTab(tabIndex.toString())"
  >
    <span class="tab-text">{{ work.tabTitle }}</span>
  </v-btn>
</template>

<script>
import {Work} from '@/models/work';

export default {
  name: 'WorksTimelineTab',
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    work: {
      type: Work,
      required: true,
    },
  },
  data: () => ({
    activeTab: '1',
  }),
  computed: {
    tabChanged() {
      return this.$store.state.WorkTabManager.activeTab;
    },
    onResize() {
      return this.$store.state.ScreenResize.currentWidth;
    },
  },
  watch: {
    tabChanged() {
      this.activeTab = this.$store.state.WorkTabManager.activeTab;
    },
    onResize() {
      this.changeTab('1');
    },
  },
  mounted() {
    this.changeTab('1');
  },
  methods: {
    changeTab(tabNum) {
      this.$store.dispatch('toggleWorkTab', {tab: tabNum, offset: 0});
    },
  },
};
</script>

<style>
.tab > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;
@import "@/assets/scss/styles/variables";

.tab {
  height: $work-timeline-tab-height;
  width: $work-timeline-tab-width;
  border-bottom: 2px solid rgba(var(--v-theme-worktab), .6);
  border-radius: 0;
  color: rgba(var(--v-theme-worktab)) !important;
  background: transparent;
  font-weight: bold;
  transition: all 0.25s ease;

  &:hover {
    @media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
      color: $work-timeline-tab-color !important;
    }
  }

  &.active {
    color: $work-timeline-tab-color !important;
  }
}
</style>
