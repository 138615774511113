import gsap from 'gsap';
import i18n from '@/plugins/i18n';
import {MouseFollowerOptionsData} from '@/assets/ts/mouse-follower/options';
import {MouseFollowerOptions, Position, Event, GsapSetter, CursorComponents} from '@/models/mouse-follower';

/**
 * MouseFollower class is responsible for creating a custom cursor that follows the user's mouse movements.
 * It supports different cursor states, transformations, and event handling.
 */
export default class MouseFollower {
  // Class properties
  options: MouseFollowerOptions = MouseFollowerOptionsData;
  cursor: CursorComponents | null;
  container: HTMLElement = document.body;
  skewing: number = 0;
  gsap: GSAP = gsap;
  pos: Position = {x: -window.innerWidth, y: -window.innerHeight};
  vel: Position = {x: 0, y: 0};
  event: Event = new Event();
  events: {[key: string]: Function[]} = {};
  setter: GsapSetter = new GsapSetter();
  stick: boolean | Position = false;
  visible: boolean = false;
  visibleInt: NodeJS.Timeout = setTimeout(() => {});
  mediaInt: NodeJS.Timeout = setTimeout(() => {});
  mediaImg: HTMLImageElement | null = null;
  ticker: () => void;

  /**
   * Creates a new MouseFollower instance.
   * @param cursorComponents Components required for the custom cursor.
   */
  constructor(cursorComponents: CursorComponents) {
    this.cursor = cursorComponents;
    this.createSetter();
    this.bindEvents();
    this.render(true);
    this.ticker = this.render.bind(this, false);
    this.gsap.ticker.add(this.ticker);
  }

  /**
   * Initializes GSAP setters for cursor components.
   */
  createSetter() {
    this.setter = {
      x: this.gsap.quickSetter(this.cursor!!.el, 'x', 'px'),
      y: this.gsap.quickSetter(this.cursor!!.el, 'y', 'px'),
      rotation: this.gsap.quickSetter(this.cursor!!.el, 'rotation', 'deg'),
      scaleX: this.gsap.quickSetter(this.cursor!!.el, 'scaleX'),
      scaleY: this.gsap.quickSetter(this.cursor!!.el, 'scaleY'),
      wc: this.gsap.quickSetter(this.cursor!!.el, 'willChange'),
      inner: {
        rotation: this.gsap.quickSetter(this.cursor!!.inner, 'rotation', 'deg'),
      },
    };
  }

  /**
   * Binds event listeners for cursor interactions and state changes.
   */
  bindEvents() {
    this.event.mouseleave = () => this.hide();
    this.event.mouseenter = () => this.show();
    this.event.mousedown = () => this.addState(this.options.activeState!!);
    this.event.mouseup = () => this.removeState(this.options.activeState!!);
    this.event.mousemoveOnce = () => this.show();
    this.event.mousemove = (e: MouseEvent) => {
      this.gsap.to(this.pos, {
        x: this.stick ? (this.stick as Position).x - (((this.stick as Position).x - e.clientX) * this.options.stickDelta) : e.clientX,
        y: this.stick ? (this.stick as Position).y - (((this.stick as Position).y - e.clientY) * this.options.stickDelta) : e.clientY,
        overwrite: this.options.overwrite,
        ease: this.options.ease,
        duration: this.visible ? this.options.speed : 0,
        onUpdate: () => {
          this.vel = {x: e.clientX - this.pos.x, y: e.clientY - this.pos.y};
        },
      });
    };
    this.event.mouseover = (e: MouseEvent) => {
      for (let target = e.target; target && target !== this.container; target = ( <HTMLElement>target ).parentNode) {
        if (e.relatedTarget && ( <HTMLElement>target ).contains(e.relatedTarget as Node)) break;

        for (const state in this.options.stateDetection) {
          if (( <HTMLElement>target ).matches(this.options.stateDetection[state])) this.addState(state);
        }
      }
    };
    this.event.mouseout = (e: MouseEvent) => {
      for (let target = e.target!!; target && target !== this.container; target = <HTMLElement>( <HTMLElement>target ).parentNode) {
        if (e.relatedTarget && ( <HTMLElement>target ).contains(e.relatedTarget as Node)) break;

        for (const state in this.options.stateDetection) {
          if (( <HTMLElement>target ).matches(this.options.stateDetection[state])) this.removeState(state);
        }
      }
    };

    if (this.options.hideOnLeave) {
      this.container.addEventListener('mouseleave', this.event.mouseleave, {passive: true});
    }
    if (this.options.visible) {
      this.container.addEventListener('mouseenter', this.event.mouseenter, {passive: true});
    }
    if (this.options.activeState) {
      this.container.addEventListener('mousedown', this.event.mousedown, {passive: true});
      this.container.addEventListener('mouseup', this.event.mouseup, {passive: true});
    }
    this.container.addEventListener('mousemove', this.event.mousemove, {passive: true});
    if (this.options.visible) {
      this.container.addEventListener('mousemove', this.event.mousemoveOnce, {
        passive: true,
        once: true,
      });
    }
    if (this.options.stateDetection) {
      this.container.addEventListener('mouseover', this.event.mouseover, {passive: true});
      this.container.addEventListener('mouseout', this.event.mouseout, {passive: true});
    }
  }

  /**
   * Updates cursor position and applies skewing effect if enabled.
   * @param force Force render, even if cursor velocity is zero.
   */
  render(force: boolean) {
    if (force !== true && (this.vel.y === 0 || this.vel.x === 0)) {
      this.setter.wc('auto');
      return;
    }

    this.trigger('render');
    this.setter.wc('transform');
    this.setter.x(this.pos.x);
    this.setter.y(this.pos.y);

    if (this.skewing) {
      const distance = Math.sqrt(Math.pow(this.vel.x, 2) + Math.pow(this.vel.y, 2));
      const scale = Math.min(distance * this.options.skewingDelta,
          this.options.skewingDeltaMax) * this.skewing;
      const angle = Math.atan2(this.vel.y, this.vel.x) * 180 / Math.PI;

      this.setter.rotation(angle);
      this.setter.scaleX(1 + scale);
      this.setter.scaleY(1 - scale);
      this.setter.inner.rotation(-angle);
    }
  }

  /**
   * Makes the cursor visible.
   */
  show() {
    this.trigger('show');
    clearInterval(this.visibleInt);
    this.visibleInt = setTimeout(() => {
      this.cursor!!.el.classList.remove(this.options.hiddenState);
      this.visible = true;
      this.render(true);
    }, this.options.showTimeout);
  }

  /**
   * Hides the cursor.
   */
  hide() {
    this.trigger('hide');
    clearInterval(this.visibleInt);
    this.cursor!!.el.classList.add(this.options.hiddenState);
    this.visibleInt = setTimeout(() => this.visible = false, this.options.hideTimeout);
  }

  /**
   * Toggles cursor visibility.
   * @param force Force the specified state (optional).
   */
  toggle(force: boolean) {
    if (force === true || force !== false && !this.visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Adds a state or multiple states to the cursor.
   * @param state State name(s).
   */
  addState(state: string) {
    this.trigger('addState', state);
    if (state === this.options.hiddenState) return this.hide();
    this.cursor!!.el.classList.add(...state.split(' '));
    if (this.options.visibleOnState) this.show();
  }

  /**
   * Removes a state or multiple states from the cursor.
   * @param state State name(s).
   */
  removeState(state: string) {
    this.trigger('removeState', state);
    if (state === this.options.hiddenState) return this.show();
    this.cursor!!.el.classList.remove(...state.split(' '));
    if (this.options.visibleOnState) this.hide();
  }

  /**
   * Toggles the specified state on the cursor.
   * @param state State name.
   * @param force Force the specified state (optional).
   */
  toggleState(state: string, force: boolean) {
    if (force === true || force !== false && !this.cursor!!.el.classList.contains(state)) {
      this.addState(state);
    } else {
      this.removeState(state);
    }
  }

  /**
   * Sets the skewing effect factor.
   * @param value Skewing factor.
   */
  setSkewing(value: number) {
    this.gsap.to(this, {skewing: value});
  }

  /**
   * Resets the skewing effect factor to its default value.
   */
  removeSkewing() {
    this.gsap.to(this, {skewing: 0});
  }

  /**
   * Sticks the cursor to the specified element.
   * @param element Element or selector.
   */
  setStick(element: string|HTMLElement) {
    const el = typeof (element) === 'string' ? document.querySelector(element) : element;
    const rect = el!!.getBoundingClientRect();
    this.stick = {
      y: rect.top + (rect.height / 2),
      x: rect.left + (rect.width / 2),
    };
  }

  /**
   * Unsticks the cursor from the current element.
   */
  removeStick() {
    this.stick = false;
  }

  /**
   * Transforms the cursor into text mode with the specified string.
   * @param text Text to display.
   */
  setText(text: string) {
    this.cursor!!.text.innerHTML = i18n.global.t(text);
    this.addState(this.options.textState);
    this.setSkewing(this.options.skewingText);
  }

  /**
   * Reverts the cursor from text mode.
   */
  removeText() {
    this.removeState(this.options.textState);
    this.removeSkewing();
  }

  /**
   * Transforms the cursor into media mode with the given element.
   *
   * @param element The element to be displayed on the cursor.
   */
  setMedia(element: HTMLElement) {
    clearTimeout(this.mediaInt);
    if (element) {
          this.cursor!!.mediaBox!!.innerHTML = '';
          this.cursor!!.mediaBox!!.appendChild(element);
    }
    this.mediaInt = setTimeout(() => this.addState(this.options.mediaState), 20);
    this.setSkewing(this.options.skewingMedia);
  }

  /**
   * Reverts the cursor from media mode.
   */
  removeMedia() {
    clearTimeout(this.mediaInt);
    this.removeState(this.options.mediaState);
    this.mediaInt = setTimeout(() => this.cursor!!.mediaBox!!.innerHTML = '', this.options.hideMediaTimeout);
    this.removeSkewing();
  }

  /**
   * Transforms the cursor into image mode.
   *
   * @param url The URL of the image to be displayed on the cursor.
   */
  setImg(url: string) {
    if (!this.mediaImg) this.mediaImg = new Image();
    if (this.mediaImg.src !== url) this.mediaImg.src = url;
    this.setMedia(this.mediaImg);
  }

  /**
   * Reverts the cursor from image mode.
   */
  removeImg() {
    this.removeMedia();
  }

  /**
   * Executes all handlers for the given event type.
   * @param event Event name.
   * @param params Optional parameters for the event handlers.
   */
  trigger(event: string, ...params: string[]) {
    if (!this.events[event]) return;
    this.events[event].forEach((f: Function) => f.call(this, this, ...params));
  }

  /**
   * Destroys the cursor instance and removes all event listeners.
   */
  destroy() {
    this.trigger('destroy');
    this.gsap.ticker.remove(this.ticker);
    this.container.removeEventListener('mouseleave', this.event.mouseleave);
    this.container.removeEventListener('mouseenter', this.event.mouseenter);
    this.container.removeEventListener('mousedown', this.event.mousedown);
    this.container.removeEventListener('mouseup', this.event.mouseup);
    this.container.removeEventListener('mousemove', this.event.mousemove);
    this.container.removeEventListener('mousemove', this.event.mousemoveOnce);
    this.container.removeEventListener('mouseover', this.event.mouseover);
    this.container.removeEventListener('mouseout', this.event.mouseout);
    if (this.cursor) {
      this.cursor = null;
      this.mediaImg = null;
    }
  }
}
