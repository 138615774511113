import Platform from '@/models/platform';

const android: Platform = new Platform(
    'Android',
    '$android',
);

const internet: Platform = new Platform(
    'Internet',
    '$internet',
);

export const platformData: Platform[] = [
  android,
  internet,
];

// This is enum in typescript
export class PlatformEnum {
  static readonly ANDROID = android;
  static readonly INTERNET = internet;
}
