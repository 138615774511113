
import {numericStateWithSpeed, renderedStyles} from '@/models/rendered-style';
import {lerp, getMousePos, adjustBoundingRect} from '@/assets/ts/utils';

const speed = 0.2;

export default class StickyBtn {
  state: { hover: boolean, destroyed: boolean};
  mousepos: { x: number, y: number } = {x: 0, y: 0};
  DOM: { btn: HTMLElement, icon: HTMLElement};
  btnBound: DOMRect = new DOMRect();
  renderedStyles: renderedStyles = {
    tx: new numericStateWithSpeed(0, 0, speed),
    ty: new numericStateWithSpeed(0, 0, speed),
  };

  boundMousePos: (ev: MouseEvent) => { x: number, y: number } = () => ({x: 0, y: 0});
  boundEnter: () => void = () => {};
  boundLeave: () => void = () => {};

  constructor(btn: HTMLElement, icon: HTMLElement) {
    this.DOM = {btn, icon};
    this.state = {hover: false, destroyed: false};

    this.bindEvents();
    this.init();
  }

  /**
     * Create and attach sticky events.
     */
  bindEvents() {
    // Bind these functions in the constructor in order to destroy them properly.
    this.boundMousePos = (ev: MouseEvent) => this.mousepos = getMousePos(ev);
    this.boundEnter = this.enter.bind(this);
    this.boundLeave = this.leave.bind(this);
  }

  /**
     * Initialize sticky effect instance.
     */
  init() {
    // Track the mouse position
    window.addEventListener('mousemove', this.boundMousePos);

    this.DOM.btn.addEventListener('mouseenter', this.boundEnter);
    this.DOM.btn.addEventListener('mouseleave', this.boundLeave);

    this.render();
  }

  /**
     * Destroy sticky events instance.
     */
  destroy() {
    window.removeEventListener('mousemove', this.boundMousePos);
    this.DOM.btn.removeEventListener('mouseenter', this.boundEnter);
    this.DOM.btn.removeEventListener('mouseleave', this.boundLeave);
    this.leave();
    this.state.destroyed = true;
  }

  /**
     * Get elements bounds.
     */
  getBounds() {
    this.btnBound = adjustBoundingRect(this.DOM.btn.getBoundingClientRect(), window.scrollX, window.scrollY);
  }

  /**
     * Get new translation values for the icon.
     *
     * @return {[number, number]} translation x, translation y.
     */
  getTranslations() : [number, number] {
    let x = 0;
    let y = 0;

    if (this.state.hover) {
      x = (this.mousepos.x + window.scrollX - (this.btnBound.left + this.btnBound.width/2))*0.15;
      y = (this.mousepos.y + window.scrollY - (this.btnBound.top + this.btnBound.height/2))*0.15;
    }

    return [x, y];
  }

  /**
     * Iterate through and filters the btn's properties, updating the previous values.
     */
  updateRenderedStyles() {
    const [x, y] = this.getTranslations();

    this.renderedStyles.tx.current = -x;
    this.renderedStyles.ty.current = -y;

    Object.keys(this.renderedStyles).forEach((key) => {
      if (key != null) {
        const previous = this.renderedStyles[key].previous;
        const current = this.renderedStyles[key].current;
        const speed = this.renderedStyles[key].speed;
        this.renderedStyles[key].previous = lerp(previous, current, speed);
      }
    });
  }

  /**
     * Render the component.
     */
  render() {
    if (!this.state.destroyed) {
      this.getBounds();
      this.updateRenderedStyles();

      // Translate icon position
      this.DOM.icon.style.transform = `translate3d(${-this.renderedStyles.tx.previous*0.6}px, ${-this.renderedStyles.ty.previous*0.6}px, 0)`;

      // Repeatedly calls a function to update the page smoothly without excessive use of resources.
      requestAnimationFrame(() => this.render());
    }
  }

  /**
     * Handle mouse enter.
     */
  enter() {
    this.state.hover = true;
  }

  /**
     * Handle mouse leave.
     */
  leave() {
    this.state.hover = false;
  }
}
