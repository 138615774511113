<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <switch>
        <foreignObject
          requiredExtensions="&ns_ai;"
          x="0"
          y="0"
          width="1"
          height="1"
        />
        <g i:extraneous="self">
          <g>
            <path
              class="st0"
              d="M4.8,27.3c-0.1,0-0.2-0.1-0.1-0.2l0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.2h11.1c0.1,0,0.2,0.1,0.1,0.2l-0.5,0.8
            c-0.1,0.1-0.2,0.2-0.3,0.2L4.8,27.3L4.8,27.3z M0.1,30.2C0,30.2,0,30.1,0,30l0.7-0.8C0.7,29.1,0.9,29,1,29h14.2
            c0.1,0,0.2,0.1,0.2,0.2l-0.2,0.7c0,0.1-0.2,0.2-0.3,0.2L0.1,30.2z M7.7,33c-0.1,0-0.2-0.1-0.1-0.2L8,32.1
            c0.1-0.1,0.2-0.2,0.3-0.2h6.2c0.1,0,0.2,0.1,0.2,0.2l-0.1,0.7c0,0.1-0.1,0.2-0.2,0.2L7.7,33L7.7,33z M40,26.7l-5.2,1.4
            c-0.5,0.1-0.5,0.2-0.9-0.3c-0.5-0.5-0.8-0.9-1.5-1.2c-2-1-3.9-0.7-5.6,0.5c-2.1,1.4-3.2,3.4-3.2,5.9c0,2.5,1.7,4.5,4.2,4.9
            c2.1,0.3,3.9-0.5,5.3-2.1c0.3-0.3,0.5-0.7,0.8-1.2h-6c-0.7,0-0.8-0.4-0.6-0.9c0.4-1,1.2-2.6,1.6-3.4c0.1-0.2,0.3-0.5,0.8-0.5H41
            c-0.1,0.8-0.1,1.7-0.2,2.5c-0.3,2.2-1.2,4.3-2.6,6.1c-2.2,3-5.2,4.8-8.9,5.3c-3.1,0.4-5.9-0.2-8.4-2.1c-2.3-1.7-3.6-4.1-4-6.9
            c-0.4-3.4,0.6-6.4,2.6-9.1c2.2-2.9,5.1-4.7,8.7-5.4c2.9-0.5,5.7-0.2,8.3,1.5c1.7,1.1,2.8,2.6,3.6,4.4
            C40.5,26.5,40.4,26.6,40,26.7L40,26.7z"
            />
            <path
              class="st0"
              d="M50.3,44c-2.8-0.1-5.4-0.9-7.6-2.7c-1.8-1.6-3-3.6-3.4-6c-0.6-3.5,0.4-6.6,2.5-9.4c2.3-3,5-4.5,8.7-5.2
            c3.2-0.6,6.2-0.2,8.9,1.6c2.5,1.7,4,4,4.4,6.9c0.5,4.2-0.7,7.6-3.6,10.6c-2.1,2.1-4.6,3.4-7.5,4C52,43.8,51.1,43.9,50.3,44
            L50.3,44z M57.7,31.4c0-0.4,0-0.7-0.1-1c-0.6-3.1-3.4-4.8-6.4-4.1c-2.9,0.7-4.8,2.5-5.5,5.4c-0.6,2.4,0.6,4.9,2.9,5.9
            c1.7,0.7,3.4,0.7,5.1-0.2C56.2,36,57.6,34,57.7,31.4L57.7,31.4z"
            />
          </g>
        </g>
      </switch>
    </svg>
  </v-row>
</template>

<style type="text/css">
    .st0{fill:#00ACD7;}
    #Layer_1{enable-background:new 0 0 64 64;}
</style>
