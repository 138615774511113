<template>
  <v-row no-gutters>
    <MenuBtn class="fab" />
    <ToTopBtn class="fab" />
    <UtilityBtns class="fab" />
    <NavigationDrawer />
    <NavigationTop />
  </v-row>
</template>

<script>

import MenuBtn from '@/pages/common/components/MenuBtn.vue';
import ToTopBtn from '@/pages/common/components/ToTopBtn.vue';
import UtilityBtns from '@/pages/common/components/UtilityBtns.vue';
import NavigationDrawer from '@/pages/common/components/NavigationDrawer.vue';
import NavigationTop from '@/pages/common/components/NavigationTop.vue';

export default {
  name: 'AppNavigation',
  components: {
    MenuBtn,
    ToTopBtn,
    UtilityBtns,
    NavigationDrawer,
    NavigationTop,
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;

$fab-right: (
  'xxl': 40px,
  'xl': 40px,
  'lg': 25px,
  'md': 25px,
  'sm': 20px,
  'xs': 20px,
);

.fab {
  position: fixed;
  @include global.for-each-breakpoint('right', $fab-right);
}
</style>
