import {createApp, watch} from 'vue';
import App from '@/App.vue';
import Router from '@/router/index';
import Vuetify from '@/plugins/vuetify';
import I18N from '@/plugins/i18n';
import VueGtag from 'vue-gtag-next';
import GtagConfig from '@/plugins/gtag';
import Vue3Marquee from 'vue3-marquee';
import {store, key} from '@/store/index';
import {loadFonts} from '@/plugins/webfontloader';
import '@/assets/scss/style.scss';

loadFonts();

const app = createApp(App);

app.use(Router);
app.use(Vuetify);
app.use(I18N);
app.use(VueGtag, GtagConfig);
app.use(Vue3Marquee);
app.use(store, key);

// Global variables
// Screen sizes for the activation(or not) of custom cursor and mouse hover animation
app.config.globalProperties.$displaySizeUp = 'lgAndUp';
app.config.globalProperties.$displaySizeDown = 'mdAndDown';

// Dynamically update the lang attribute of the <html> tag
watch(
    () => I18N.global.locale,
    (newLocale: string) => {
      document.querySelector('html')?.setAttribute('lang', newLocale);
    },
);

// Set the initial lang attribute
document.querySelector('html')?.setAttribute('lang', I18N.global.locale);

app.mount('#app');
