<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
      }
    }"
    class="typing-container pb-14"
    :class="{'justify-center': title === 'footer.title'}"
  >
    <span class="typing-text"> <b>{{ typingText }}</b> </span>
    <span
      v-if="!cursorVisible"
      class="typing-text"
    >{{ emoji }}</span>
    <span
      v-if="cursorVisible"
      class="typing-cursor"
    />
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    emoji: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    titleTranslated: '',
    typingText: '',
    cursorVisible: true,
    typingStarted: false,
    animEnabled: false,
  }),
  computed: {
    langChanged() {
      return this.$i18n.locale;
    },
    screenSizeChanged() {
      return this.$store.state.ScreenResize.currentWidth;
    },
  },
  watch: {
    langChanged() {
      this.titleTranslated = this.$i18n.t(this.title);
      this.typingText = !this.animEnabled ? this.titleTranslated : this.typing();
    },
    screenSizeChanged() {
      this.animEnabled = this.$vuetify.display[this.$displaySizeUp];
    },
  },
  mounted() {
    this.titleTranslated = this.$i18n.t(this.title);
    this.animEnabled = this.$vuetify.display[this.$displaySizeUp];
    if (!this.animEnabled) {
      this.typingText = this.titleTranslated;
      this.cursorVisible = false;
    }
  },
  methods: {
    typing() {
      let i = 0;
      this.typingStarted = true;

      const typing = () => {
        if (i <= this.titleTranslated.length) {
          this.typingText = this.titleTranslated.slice(0, i);
          const timeout = this.titleTranslated[i - 1] === ' ' ? 0: 100;
          i++;
          setTimeout(typing, timeout);
        } else {
          this.cursorVisible = false;
        }
      };
      typing();
    },
    onIntersect(isIntersecting, entries, observer) {
      if (isIntersecting && !this.typingStarted && this.animEnabled) {
        setTimeout(() => {
          this.typing();
        }, 0);
      };
    },
  },
};
</script>

<style lang="scss">
$font-size: 40px;

.typing-container {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: $font-size + 0px;
  word-wrap: break-all;
}

.typing-text {
  font-size: $font-size;
  font-weight: bold;
  white-space: pre-wrap;
}

.typing-cursor {
  display: inline-block;
  width: 3px;
  height: $font-size;
  margin-left: 8px;
  background-color: rgb(var(--v-theme-primary));
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
