<!-- eslint-disable max-len -->
<template>
  <section
    id="project"
    class="bg-background"
  >
    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
        }
      }"
      class="project-container g-section-container"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="12" class="slide-in-bottom-1" :class="{ 'entered': entered }">
          <SectionTitle
            :title="'home.project.title'"
          />
        </v-col>
        <v-col cols="12 pb-7">
          <p class="project-text text-textcolor slide-in-bottom-2" :class="{ 'entered': entered }">
            {{ $t('home.project.description') }}
          </p>
          <!-- // TODO -->
          <v-icon
            class=" slide-in-bottom-3"
            :class="{ 'entered': entered }"
            size="50"
            color="red"
          >
            mdi-alert-outline
          </v-icon>
          <span class="project-text text-textcolor slide-in-bottom-4" :class="{ 'entered': entered }">
            {{ $t('home.project.description2') }}
          </span>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <template
          v-for="(project, index) in projects"
          :key="index"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="d-flex"
            :class="[getCardPadding(index), generateCardIndexClass(index)]"
          >
            <ProjectCard :class="[getSlideInClass(index), { 'entered': entered }]" :project="project" :parent-class="'.'+generateCardIndexClass(index)" />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SectionTitle from '@/pages/home/components/SectionTitle.vue';
import ProjectCard from '@/pages/home/components/ProjectCard.vue';
import ProjectsData from '@/pages/home/data/projects/index';
import {useIntersection} from '@/composables/intersection';

export default {
  name: 'ProjectSection',
  components: {
    SectionTitle,
    ProjectCard,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    return {entered, onIntersect};
  },
  data: () => ({
    selection: 0,
    projects: ProjectsData,
  }),
  methods: {
    getCardPadding(index) {
      let colsCount;

      if (this.$vuetify.display.mdAndUp) {
        colsCount = 3;
      } else if (this.$vuetify.display.sm) {
        colsCount = 2;
      } else {
        return 'py-3 px-0'; // Return padding for single column layout.
      }

      // Calculate whether the card is the first, last, or in the middle of its row.
      const isFirst = index % colsCount === 0;
      const isLast = (index + 1) % colsCount === 0;

      // Return the appropriate padding classes based on the card's position in the row.
      if (isFirst) {
        return `py-3 pl-0 pr-${colsCount === 2 ? 3 : 4}`;
      } else if (isLast) {
        return `py-3 pl-${colsCount === 2 ? 3 : 4} pr-0`;
      } else {
        return colsCount === 2 ? 'pa-3' : 'py-3 px-2';
      }
    },
    getSlideInClass(index) {
      return `slide-in-bottom-${3 + index}`;
    },
    generateCardIndexClass(index) {
      return `card-${index}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$project-length: 4;

$project-container-padding-top: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

$project-container-padding-bottom: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

.project-container {
  @include global.for-each-breakpoint('padding-top', $project-container-padding-top);
  @include global.for-each-breakpoint('padding-bottom', $project-container-padding-bottom);
}

.project-swipe-drag {
  color: rgb(var(--v-theme-primary));
}

@for $i from 0 through $project-length {
  .card-#{$i} {
    position: relative;
  }
}
</style>
