<template>
  <div
    ref="rollingText"
    class="rolling-text"
    @click="scrollTo"
  >
    <div
      v-for="block of [1, 2]"
      :key="block"
      class="block"
    >
      <div
        v-for="(letter, index) of $t(text).trim()"
        :key="index"
        class="letter"
      >
        <b>{{ letter.trim() === '' ? '\xa0' : letter }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import useNavigation from '@/composables/navigation';
import MagneticTextEffect from '@/pages/common/assets/ts/effect/magnefic-text';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    goTo: {
      type: String,
      required: true,
    },
  },
  setup() {
    const navigation = useNavigation();
    return {navigation};
  },
  data: () => ({
    magnetic: MagneticTextEffect.prototype,
    animEnabled: false,
    rollingText: HTMLElement,
  }),
  computed: {
    screenSizeChanged() {
      return this.$vuetify.display[this.$displaySizeUp];
    },
  },
  watch: {
    screenSizeChanged() {
      this.animEnabled = !this.animEnabled;
      this.toggleMagneticTextEffect();
    },
  },
  mounted() {
    this.rollingText = this.$refs.rollingText;
    this.animEnabled = this.$vuetify.display[this.$displaySizeUp] ? true : false;

    this.rollingText.addEventListener('mouseover', () => {
      this.rollingText.classList.remove('play');
    });

    this.toggleMagneticTextEffect();
  },
  methods: {
    toggleMagneticTextEffect() {
      this.magnetic = this.animEnabled ? new MagneticTextEffect(this.rollingText) :
        (this.magnetic instanceof MagneticTextEffect) ? this.magnetic.destroy() : undefined;
    },
    scrollTo() {
      this.$store.dispatch('toggleMenu');
      this.navigation.scrollTo(this.goTo);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$delay: 0.015s;
$duration: 0.5s;
$easing: cubic-bezier(0.76, 0, 0.24, 1);

$line-height: (
  'xxl': 70px,
  'xl': 70px,
  'lg': 70px,
  'md': 55px,
  'sm': 55px,
  'xs': 55px,
);

$font-size: (
  'xxl': 60px,
  'xl': 60px,
  'lg': 60px,
  'md': 45px,
  'sm': 45px,
  'xs': 45px,
);

.rolling-text {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  color: rgb(var(--v-theme-primary));
  margin-left: -1px;

  @include global.for-each-breakpoint('line-height', $line-height);
  @include global.for-each-breakpoint('height', $line-height);
  @include global.for-each-breakpoint('font-size', $font-size);

  @media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
    &:hover,
    &.play {
      .letter {
        transform: translateY(-100%);
      }
    }
  }

  .block:last-child {
    color: rgb(var(--v-theme-primary));
  }

  .letter {
    display: inline-block;
    transition: transform $duration $easing;
  }
}

@for $i from 0 through 20 {
  .letter:nth-child(#{$i + 1}) {
    transition-delay: $delay * $i;
  }
}
</style>
