import {landing, textRotator} from '@/translations/en/home-page/landing';
import {about} from '@/translations/en/home-page/about';
import {work} from '@/translations/en/home-page/work';
import {works} from '@/translations/en/home-page/works/index';
import {proficiency} from '@/translations/en/home-page/proficiency';
import {skill} from '@/translations/en/home-page/skill';
import {project} from '@/translations/en/home-page/project';
import {projects} from '@/translations/en/home-page/projects/index';

export const home = {
  landing,
  about,
  textRotator,
  work,
  works,
  proficiency,
  skill,
  project,
  projects,
};
