/* eslint-disable max-len */
import {ProjectInterface} from '@/models/project';
import {PlatformEnum} from '@/pages/home/data/platforms';
import {TechnologyEnum} from '@/pages/home/data/technologies';

const descriptionTranslation = 'home.projects.rollingBall.description';
const priceTranslation = 'home.projects.rollingBall.price';

const ROLLING_BALL: ProjectInterface = {
  title: 'Rolling Ball',
  description: descriptionTranslation,
  logo: 'rollingBallLogo.png',
  price: priceTranslation,
  link: 'https://play.google.com/store/apps/details?id=com.MarcoLanfranchi.RollingBall',
  platforms: [PlatformEnum.ANDROID.name],
  technologies: [TechnologyEnum.UNITY.name, TechnologyEnum.CSHARP.name],
};

export default ROLLING_BALL;
