<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <path
        d="M15.91 0H9.38849L15.91 15.4142"
        fill="#1697F6"
      />
      <path
        d="M15.9099 19.9386V31.9022L1.95251 4.65234H9.2637"
        fill="#7BC6FF"
      />
      <path
        d="M15.91 0H22.4315L15.91 15.4142"
        fill="#1867C0"
      />
      <path
        d="M15.91 19.9386V31.9022L29.8674 4.65234H22.5562"
        fill="#AEDDFF"
      />
    </svg>
  </v-row>
</template>
