<template>
  <div
    v-show="fab"
    class="utility-box"
  >
    <v-scroll-x-reverse-transition>
      <v-row
        v-show="fab"
        no-gutters
        class="d-flex flex-column"
      >
        <v-col cols="12">
          <v-btn
            class="utility-btn"
            color="transparent"
            icon
            elevation="0"
            :ripple="false"
            @click="toggleLang"
          >
            {{ $t('drawer.lang') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
export default {
  name: 'ToolsBtns',
  data: () => ({
    fab: false,
  }),
  computed: {
    toggleFab() {
      return this.$store.state.MenuManager.isOpen;
    },
  },
  watch: {
    toggleFab() {
      this.fab = !this.fab;
    },
  },
  mounted() {
    this.fab = this.$store.state.MenuManager.isOpen;
  },
  methods: {
    toggleLang() {
      const lang = this.$i18n.locale === 'en' ? 'it' : 'en';
      this.$router.push({name: this.$route.name, params: {lang: lang}});
    },
  },
};
</script>

<style>
.utility-btn > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style scoped>
.utility-box {
  z-index: 1500;
}

.utility-btn:hover {
  transform: scale(1.2);
  transition-duration: 0.2s;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;

$utility-box-bottom: (
  'xxl': 40px,
  'xl': 40px,
  'lg': 35px,
  'md': 35px,
  'sm': 30px,
  'xs': 30px,
);

.utility-box {
  @include global.for-each-breakpoint('bottom', $utility-box-bottom);
}
</style>
