<template>
  <v-btn
    ref="btn"
    class="footer-social-btn mx-2"
    icon
    elevation="0"
    :ripple="false"
    variant="outlined"
    :href="social.link"
    target="_blank"
    color="footer"
    @mouseenter="setHoverEffect"
    @mouseleave="setHoverEffect"
  >
    <v-icon class="footer-social-icon">
      {{ social.icon }}
    </v-icon>
  </v-btn>
</template>

<script>
import Social from '@/models/social';

export default {
  name: 'FooterSocialBtn',
  props: {
    social: {
      type: Social,
      required: true,
    },
  },
  data: () => ({
    btn: HTMLElement,
  }),
  mounted() {
    this.btn = this.$refs.btn.$el;
  },
  methods: {
    setHoverEffect(event) {
      const footerContainer = document.querySelector('.footer-container');
      const footerContainerStyle = getComputedStyle(footerContainer);

      const x = this.getOffsetX(event, footerContainer);
      const y = this.getOffsetY(event, footerContainer, footerContainerStyle);

      this.btn.style.setProperty('--x', x + 'px');
      this.btn.style.setProperty('--y', y + 'px');
    },

    getOffsetX(event, footerContainer) {
      const parentWidth = footerContainer.parentElement.offsetWidth;
      const containerWidth = footerContainer.offsetWidth;
      // Calculate the effective margin by subtracting the container width from the parent width and dividing by 2 (left/right)
      const marginLeftValue = `${(parentWidth - containerWidth) / 2}px`;

      // This is necessary because the footer container has a dynamic margin
      const btnOffsetLeft = this.btn.offsetLeft + parseInt(marginLeftValue);

      return event.pageX - btnOffsetLeft;
    },

    getOffsetY(event, footerContainer, footerContainerStyle) {
      const transformValue = footerContainerStyle.transform;
      // EX matrixValues = ['1', '0', '0', '1', '0', '-103.013']
      const matrixValues = transformValue.substring(7, transformValue.length - 1).split(', ');
      // Get the translation of the footer along the Y axis
      const footerTranslateY = parseFloat(matrixValues[5]);

      // This is necessary because the footer has a translation prop, so the btn offsetTop is the offset from the top of the footer and not the page.
      const btnOffsetTop = this.btn.offsetTop + footerContainer.offsetTop + footerTranslateY;

      return event.pageY - btnOffsetTop;
    },
  },
};
</script>

<style>
.footer-social-btn > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style lang="scss">
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.footer-social-btn {
  overflow: hidden;
}

@media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
  // Credits https://www.youtube.com/watch?v=WOSaNbpHNqU
  .footer-social-icon {
    // z-index: 10;
    transition: color 0.7s;
    color: rgb(var(--v-theme-background));
  }

  .footer-social-btn:hover
  .footer-social-icon {
    color: rgb(var(--v-theme-primary));
    transition: color 0.5s;
  }
  .footer-social-btn::before {
    content: "";
    display: block;
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background: rgb(var(--v-theme-background));
    transition: width 0.5s, height 0.5s;
  }
  .footer-social-btn:hover::before {
    width: 500px;
    height: 500px;
  }
}

@media #{map-get(vuetify.$display-breakpoints, global.$display-size-down)} {
  .footer-social-icon {
    color: rgb(var(--v-theme-background));
  }
}
</style>
