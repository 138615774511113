<template>
  <v-layout-item
    size="auto"
    model-value
    position="top"
    absolute
    class="nav-top"
  >
    <v-row
      no-gutters
      align="center"
      class="fill-height"
    >
      <p><b>M.LANFRA</b></p>

      <v-spacer />

      <p class="menu-text">
        <b>MENU</b>
      </p>
    </v-row>
  </v-layout-item>
</template>

<script>
export default {
  name: 'NavigationTop',
  components: {
  },
};
</script>

<style lang="scss" scoped>
@use "vuetify/styles/settings/variables" as vuetify;

// Padding top is same as menu-btn-top +14px
.nav-top {
  @media #{map-get(vuetify.$display-breakpoints, 'xxl')} {
    padding-top: 54px;
    padding-left: 55px;
    padding-right: 110px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'xl')} {
    padding-top: 54px;
    padding-left: 55px;
    padding-right: 110px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'lg')} {
    padding-top: 49px;
    padding-left: 35px;
    padding-right: 90px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'md')} {
    padding-top: 49px;
    padding-left: 30px;
    padding-right: 90px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'sm')} {
    padding-top: 43px;
    padding-left: 25px;
    padding-right: 80px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'xs')} {
    padding-top: 33px;
    padding-left: 26px;
    padding-right: 80px;
  }
}
</style>
