import {ActionContext} from 'vuex';

export interface WorkTabState {
  activeTab: number;
  highlighterTransform: string;
}

const state: WorkTabState = {
  activeTab: 0,
  highlighterTransform: `translateY(0px)`,
};

export const WorkTabManager = {
  namespaced: false,
  state,
  mutations: {
    toggleTab(state: WorkTabState, payload: { tab: number, offset: number }) {
      const {tab, offset} = payload;

      // 130 is tab fixed width
      const translateX = `translateX(${((tab - 1) * 130) + offset}px)`;

      state.activeTab = tab;
      state.highlighterTransform = translateX;
    },
  },
  actions: {
    toggleWorkTab(context: ActionContext<WorkTabState, any>, payload: {tab: number, offset: number}) {
      context.commit('toggleTab', payload);
    },
  },
};
