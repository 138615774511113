<template>
  <div
    ref="cursor"
    :class="{'mf-cursor': $vuetify.display[$displaySizeUp]}"
  >
    <div
      ref="inner"
      class="mf-cursor-inner"
    >
      <div
        ref="media"
        class="mf-cursor-media"
      >
        <div
          ref="mediaBox"
          class="mf-cursor-media-box"
        />
      </div>
      <div
        ref="text"
        class="mf-cursor-text"
      />
    </div>
  </div>
</template>

<script>
import MouseFollower from '@/assets/ts/mouse-follower/mouse-follower';
import MouseEvents from '@/assets/ts/mouse-follower/mouse-events';
import {CursorComponents} from '@/models/mouse-follower';

export default {
  name: 'CustomCursor',
  components: {
  },
  data: () => ({
    animEnabled: false,
    mouseFollower: MouseFollower.prototype,
    mouseEvents: MouseEvents.prototype,
  }),
  computed: {
    screenSizeChanged() {
      return this.$vuetify.display[this.$displaySizeUp];
    },
  },
  watch: {
    screenSizeChanged() {
      this.animEnabled = !this.animEnabled;
      this.toggleCursor();
    },
  },
  mounted() {
    requestAnimationFrame(() => {
      this.animEnabled = this.$vuetify.display[this.$displaySizeUp] ? true : false;
      this.toggleCursor();
    });
  },
  methods: {
    toggleCursor() {
      if (this.animEnabled) {
        this.mouseFollower = new MouseFollower(this.buildCursorComponents());
        this.mouseEvents = new MouseEvents(this.mouseFollower);
      } else {
        if (this.mouseFollower instanceof MouseFollower) {
          this.mouseEvents.destroyEvents();
          this.mouseFollower.destroy();
        }
      }
    },
    buildCursorComponents() {
      return new CursorComponents(
          this.$refs.cursor,
          this.$refs.inner,
          this.$refs.text,
          this.$refs.media,
          this.$refs.mediaBox,
      );
    },
  },
};
</script>

<style>
</style>
