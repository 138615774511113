import Technology from '@/models/technology';

const csharp: Technology = new Technology(
    'C#',
    '$csharp',
);

const css: Technology = new Technology(
    'Css',
    '$css',
);

const dart: Technology = new Technology(
    'Dart',
    '$dart',
);

const firebase: Technology = new Technology(
    'Firebase',
    '$firebase',
);

const flutter: Technology = new Technology(
    'Flutter',
    '$flutter',
);

const git: Technology = new Technology(
    'Git',
    '$git',
);

const golang: Technology = new Technology(
    'Golang',
    '$golang',
);

const html: Technology = new Technology(
    'Html',
    '$html',
);

const postgres: Technology = new Technology(
    'Postgres',
    '$postgres',
);

const unity: Technology = new Technology(
    'Unity',
    '$unity',
);

const vue: Technology = new Technology(
    'Vue',
    '$vue',
);

const vuetify: Technology = new Technology(
    'Vuetify',
    '$vuetify',
);

export const technologyData: Technology[] = [
  csharp,
  css,
  dart,
  firebase,
  flutter,
  git,
  golang,
  html,
  postgres,
  unity,
  vue,
  vuetify,
];

// This is enum in typescript
export class TechnologyEnum {
  static readonly CSHARP = csharp;
  static readonly CSS = css;
  static readonly DART = dart;
  static readonly FIREBASE = firebase;
  static readonly FLUTTER = flutter;
  static readonly GIT = git;
  static readonly GOLANG = golang;
  static readonly HTML = html;
  static readonly POSTGRES = postgres;
  static readonly UNITY = unity;
  static readonly VUE = vue;
  static readonly VUETIFY = vuetify;
}
