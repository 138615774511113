import {Project} from '@/models/project';
import RollingBall from '@/pages/home/data/projects/rolling-ball';
import Listys from '@/pages/home/data/projects/listys';
import Wip from '@/pages/home/data/projects/work-in-progress';

const projects: Array<Project> = [
  RollingBall,
  Listys,
  Wip,
];

export default projects;
