/* eslint-disable max-len */
import {Work} from '@/models/work';

const works: Work[] = [
  new Work(
      'home.works.sorintTek.title',
      'Sorint.tek',
      'Sorint.tek',
      'https://www.latek.it/',
      'home.works.sorintTek.start',
      'home.works.sorintTek.end',
      ['home.works.sorintTek.description', 'home.works.sorintTek.description2', 'home.works.sorintTek.description3'],
  ),
  new Work(
      'home.works.sorintLab.title',
      'Sorint.lab',
      'Sorint.lab',
      'https://www.sorint.com/',
      'home.works.sorintLab.start',
      'home.works.sorintLab.end',
      ['home.works.sorintLab.description', 'home.works.sorintLab.description2', 'home.works.sorintLab.description3'],
  ),
  new Work(
      'home.works.emb.title',
      'EMB Balduzzi',
      'EMB Balduzzi',
      'https://www.embbalduzzi.it/',
      'home.works.emb.start',
      'home.works.emb.end',
      ['home.works.emb.description', 'home.works.emb.description2'],
  ),
  new Work(
      'home.works.comelit.title',
      'Comelit',
      'Comelit',
      'https://comelitgroup.it/',
      'home.works.comelit.start',
      'home.works.comelit.end',
      ['home.works.comelit.description', 'home.works.comelit.description2'],
  ),
];

export default works;
