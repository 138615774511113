<template>
  <transition name="fade">
    <div
      v-show="!isloaded"
      class="page-loader"
    >
      <LoaderSvg :enter="true" :anim-duration-seconds="totalLoaderAnimSec" />
    </div>
  </transition>
</template>

<script>
import LoaderSvg from '@/pages/common/assets/svg/LoaderSvg.vue';

export default {
  name: 'LoaderApp',
  components: {
    LoaderSvg,
  },
  data: () => ({
    isloaded: false,
    totalLoaderAnimSec: 4,
  }),
  watch: {
    isloaded(newValue) {
      if (newValue) {
        document.documentElement.style.overflow = 'auto';
        this.onLoaderFinished();
      }
    },
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden';
    setTimeout(() => {
      this.isloaded = true;
    }, this.totalLoaderAnimSec * 1000);
  },

  methods: {
    onLoaderFinished() {
      this.$store.dispatch('setLoading', false);
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$loader-padding-inline: (
  'xxl': 25%,
  'xl': 20%,
  'lg': 15%,
  'md': 10%,
  'sm': 10%,
  'xs': 10%,
);

.fade-leave-active {
  transition: opacity 1s, transform 1s;
}
.fade-leave-to{
  opacity: 0;
  transform: scale(2) !important;
}

.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 999999;

  @include global.for-each-breakpoint('padding-inline', $loader-padding-inline);

  @media #{map-get(vuetify.$display-breakpoints, 'sm-and-down')} {
    height: 100dvh;
  }
}
</style>
