import {sorintTek} from '@/translations/en/home-page/works/sorint-tek';
import {sorintLab} from '@/translations/en/home-page/works/sorint-lab';
import {emb} from '@/translations/en/home-page/works/emb';
import {comelit} from '@/translations/en/home-page/works/comelit';

export const works = {
  sorintTek,
  sorintLab,
  emb,
  comelit,
};
