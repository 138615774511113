<template>
  <v-btn
    ref="btn"
    class="scroll-btn"
    elevation="0"
    :ripple="false"
    variant="outlined"
    size="x-large"
    :style="bgColorStyle"
    :disabled="disabled"
    @click="() => navigation.scrollTo(goTo)"
    @mouseenter="setHoverEffect"
    @mouseleave="setHoverEffect"
  >
    <span class="scroll-btn-text"> <b>{{ customText }}</b> </span>
  </v-btn>
</template>

<script>
import useNavigation from '@/composables/navigation';

export default {
  name: 'ScrollBtn',
  props: {
    goTo: {
      type: String,
      required: true,
    },
    customText: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    parentClass: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const navigation = useNavigation();
    return {navigation};
  },
  data: () => ({
    btn: HTMLElement,
  }),
  computed: {
    bgColorStyle() {
      return `--scroll-btn-bg-color: var(--v-theme-${this.bgColor});`;
    },
  },
  mounted() {
    this.btn = this.$refs.btn.$el;
  },
  methods: {
    setHoverEffect(event) {
      const parentEl = document.querySelector(this.parentClass);

      const transformValue = window.getComputedStyle(parentEl).getPropertyValue('transform');

      // EX matrixValues = ['1', '0', '0', '1', '0', '-103.013']
      const matrixValues = transformValue.substring(7, transformValue.length - 1).split(', ');

      // Get the translation of the parentEl along the Y axis, if there is no trasform class sets 0
      const parentElTranslateY = transformValue === 'none' ? 0 : parseFloat(matrixValues[5]);

      // eslint-disable-next-line max-len
      // This is necessary because the parentEl has a translation prop, so the btn offsetTop is the offset from the top of the parentEl and not the page.
      const btnOffsetTop = this.btn.offsetTop + parentEl.offsetTop + parentElTranslateY;
      // This is necessary because the parentClass isn't the section but the container, so there is container margin to add.
      const btnOffsetLeft = this.btn.offsetLeft + parentEl.offsetLeft;

      const x = event.pageX - btnOffsetLeft;
      const y = event.pageY - btnOffsetTop;

      this.btn.style.setProperty('--x', x + 'px');
      this.btn.style.setProperty('--y', y + 'px');
    },
  },
};
</script>

<style>
.scroll-btn > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.scroll-btn {
  overflow: hidden;
}

// Credits https://www.youtube.com/watch?v=WOSaNbpHNqU
@media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
  .scroll-btn {
    background-color: rgb(var(--scroll-btn-bg-color));
  }

  .scroll-btn-text {
    z-index: 1;
    transition: color 0.7s;
  }

  .scroll-btn:hover
  .scroll-btn-text {
    color: rgb(var(--v-theme-background));
    transition: color 0.5s;
  }
  .scroll-btn::before {
    content: "";
    display: block;
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background: rgb(var(--v-theme-primary));
    transition: width 0.5s, height 0.5s;
  }
  .scroll-btn:hover::before {
    width: 500px;
    height: 500px;
  }
}

@media #{map-get(vuetify.$display-breakpoints, global.$display-size-down)} {
  .scroll-btn {
    background: rgb(var(--v-theme-primary));
  }
  .scroll-btn-text {
    color: rgb(var(--v-theme-background));
  }
}
</style>
