import Social from '@/models/social';

export const SocialsData: Social[] = [
  new Social(
      'Instagram',
      'https://www.instagram.com/_lanfra_/',
      'mdi-instagram',
  ),
  new Social(
      'Email',
      'mailto:lanfranchi.marco.l@gmail.com',
      'mdi-email',
  ),
  new Social(
      'Linkedin',
      'https://www.linkedin.com/in/marco-lanfranchi-b04752198/',
      'mdi-linkedin',
  ),
];

// This is enum in typescript
export class SocialsEnum {
  static readonly INSTAGRAM = SocialsData[0];
  static readonly EMAIL = SocialsData[1];
  static readonly LINKEDIN = SocialsData[2];
}
