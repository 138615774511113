import MouseFollower from './mouse-follower';

export default class MouseEvent {
  mouseFollower: MouseFollower;
  menuBtn: HTMLElement;
  titleWrapperImg: HTMLElement;
  scrollBtns: NodeListOf<HTMLElement>;
  rollingTexts: NodeListOf<HTMLElement>;
  footer: HTMLElement;

  boundEnterMenuBtn: () => void = () => {};
  boundLeaveMenuBtn: () => void = () => {};
  boundEnterTitleWrapperImg: () => void = () => {};
  boundLeaveTitleWrapperImg: () => void = () => {};
  boundEnterScrollBtn: () => void = () => {};
  boundLeaveScrollBtn: () => void = () => {};
  boundEnterRollingText: () => void = () => {};
  boundLeaveRollingText: () => void = () => {};
  boundEnterFooter: () => void = () => {};
  boundLeaveFooter: () => void = () => {};

  constructor(mouseFollower: MouseFollower) {
    this.mouseFollower = mouseFollower;
    this.menuBtn = document.querySelector('.menu-btn') as HTMLElement;
    this.titleWrapperImg = document.querySelector('.title-wrapper') as HTMLElement;
    this.scrollBtns = document.querySelectorAll('.scroll-btn') as NodeListOf<HTMLElement>;
    this.rollingTexts = document.querySelectorAll('.rolling-text') as NodeListOf<HTMLElement>;
    this.footer = document.querySelector('.footer') as HTMLElement;

    this.bindEvents();
    this.initEvents();
  }

  /**
     * Create and attach cursor events.
     */
  bindEvents() {
    // Bind these functions in the constructor in order to destroy them properly
    this.boundEnterMenuBtn = this.enterMenuBtn.bind(this);
    this.boundLeaveMenuBtn = this.leaveMenuBtn.bind(this);
    this.boundEnterTitleWrapperImg = this.enterTitleWrapperImg.bind(this);
    this.boundLeaveTitleWrapperImg = this.leaveTitleWrapperImg.bind(this);
    this.boundEnterScrollBtn = this.enterScrollBtn.bind(this);
    this.boundLeaveScrollBtn = this.leaveScrollBtn.bind(this);
    this.boundEnterRollingText = this.enterRollingText.bind(this);
    this.boundLeaveRollingText = this.leaveRollingText.bind(this);
    this.boundEnterFooter = this.enterFooter.bind(this);
    this.boundLeaveFooter = this.leaveFooter.bind(this);
  }

  /**
     * Initialize cursor events instance.
     */
  initEvents() {
    this.menuBtn.addEventListener('mouseenter', this.boundEnterMenuBtn);
    this.menuBtn.addEventListener('mouseleave', this.boundLeaveMenuBtn);

    this.titleWrapperImg.addEventListener('mouseenter', this.boundEnterTitleWrapperImg);
    this.titleWrapperImg.addEventListener('mouseleave', this.boundLeaveTitleWrapperImg);

    this.scrollBtns.forEach((scrollBtn) => {
      scrollBtn.addEventListener('mouseenter', this.boundEnterScrollBtn);
      scrollBtn.addEventListener('mouseleave', this.boundLeaveScrollBtn);
    });

    this.rollingTexts.forEach((rollingText) => {
      rollingText.addEventListener('mouseenter', this.boundEnterRollingText);
      rollingText.addEventListener('mouseleave', this.boundLeaveRollingText);
    });

    this.footer.addEventListener('mouseenter', this.boundEnterFooter);
    this.footer.addEventListener('mouseleave', this.boundLeaveFooter);
  }

  /**
     * Destroy cursor events instance.
     */
  destroyEvents() {
    this.menuBtn.removeEventListener('mouseenter', this.boundEnterMenuBtn);
    this.menuBtn.removeEventListener('mouseleave', this.boundLeaveMenuBtn);

    this.titleWrapperImg.removeEventListener('mouseenter', this.boundEnterTitleWrapperImg);
    this.titleWrapperImg.removeEventListener('mouseleave', this.boundLeaveTitleWrapperImg);

    this.scrollBtns.forEach((scrollBtn) => {
      scrollBtn.removeEventListener('mouseenter', this.boundEnterScrollBtn);
      scrollBtn.removeEventListener('mouseleave', this.boundLeaveScrollBtn);
    });


    this.rollingTexts.forEach((rollingText) => {
      rollingText.removeEventListener('mouseenter', this.boundEnterRollingText);
      rollingText.removeEventListener('mouseleave', this.boundLeaveRollingText);
    });

    this.footer.removeEventListener('mouseenter', this.boundEnterFooter);
    this.footer.removeEventListener('mouseleave', this.boundLeaveFooter);
  }

  // MenuBtn events
  enterMenuBtn() {
    this.mouseFollower.setStick(this.menuBtn);
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.cursor!!.el.classList.add('-scale-menu-btn');
  }
  leaveMenuBtn() {
    this.mouseFollower.removeStick();
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.cursor!!.el.classList.remove('-scale-menu-btn');
  }

  // TitleWrapperImg events
  enterTitleWrapperImg() {
    // ! TODO change img
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.setImg(require(`@/assets/img/marcolanfranchi.png`));
  }
  leaveTitleWrapperImg() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.removeImg();
  }

  // ScrollBtn events
  enterScrollBtn() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.addState('-exclusion');
  }
  leaveScrollBtn() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.removeState('-exclusion');
  }

  // RollingText events
  enterRollingText() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.addState('-exclusion');
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.cursor!!.el.classList.add('-scale-menu-btn');
  }
  leaveRollingText() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.removeState('-exclusion');
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.cursor!!.el.classList.remove('-scale-menu-btn');
  }

  // Footer events
  enterFooter() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.addState('-exclusion');
  }
  leaveFooter() {
    if (this.mouseFollower.cursor!!.el) this.mouseFollower.removeState('-exclusion');
  }
}
