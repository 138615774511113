import {ActionContext} from 'vuex';

export interface LoaderState {
  isLoading: boolean;
}

const state: LoaderState = {
  isLoading: true,
};

export const LoaderManager = {
  namespaced: false,
  state,
  mutations: {
    setLoading(state: LoaderState, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    setLoading(context: ActionContext<LoaderState, any>, isLoading: boolean) {
      context.commit('setLoading', isLoading);
    },
  },
};
