<template>
  <Transition
    name="fade"
  >
    <div
      v-show="fab"
      class="to-top-box"
    >
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        class="to-top-btn"
        color="primary"
        icon
        elevation="0"
        :ripple="false"
        @click="toTop"
      >
        <v-icon
          size="50"
        >
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ToTopBtn',
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      const top = window?.scrollY || e.target.scrollTop || 0;
      const offsetTop = this.$vuetify.display.xs ? 80 : 0;
      this.fab = top > offsetTop;
    },
    toTop() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },
  },
};
</script>

<style>
.to-top-btn > .v-btn__overlay {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;

$totop-btn-bottom: (
  'xxl': 40px,
  'xl': 40px,
  'lg': 35px,
  'md': 35px,
  'sm': 30px,
  'xs': 30px,
);

.to-top-box {
  z-index: 10;
  @include global.for-each-breakpoint('bottom', $totop-btn-bottom);
}

.to-top-box:hover {
  transform: scale(1.2);
  transition-duration: 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transform-origin: center;
  transition: opacity 0.7s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
