<template>
  <section
    id="footer"
    class="footer bg-footer"
  >
    <v-container class="footer-container g-section-container">
      <v-row
        no-gutters
        class="footer-row"
      >
        <v-col cols="12">
          <SectionTitle
            :title="'footer.title'"
            :emoji="'&#x1F44B;'"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-center pb-3"
        >
          <span class="footer-text">{{ $t('footer.description') }}</span>
        </v-col>
        <v-col
          cols="12"
          class="text-center pb-5"
        >
          <a
            class="underline-effect"
            :href="socialsEnum.EMAIL.link"
            target="_blank"
          >
            {{ socialsEnum.EMAIL.link.replace(/^mailto:/i, '') }}
          </a>
        </v-col>
        <v-col
          cols="12"
          class="text-center pb-16"
        >
          <template
            v-for="(social, i) in socials"
            :key="i"
          >
            <FooterSocialBtn :social="social" />
          </template>
        </v-col>
        <v-col
          cols="12"
          class="text-center pt-16"
        >
          Copyright ©&nbsp;<strong>
            {{ new Date().getFullYear() }}
          </strong>. All rights reserved.
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SectionTitle from '@/pages/home/components/SectionTitle.vue';
import {SocialsData, SocialsEnum} from '@/pages/common/data/socials';
import FooterSocialBtn from '@/pages/common/components/FooterSocialBtn.vue';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

export default {
  name: 'AppFooter',
  components: {
    SectionTitle,
    FooterSocialBtn,
  },
  data: () => ({
    socials: SocialsData,
    socialsEnum: SocialsEnum,
    uncover: gsap.core.Timeline,
    myScrollTrigger: null,
  }),
  computed: {
    onResize() {
      return this.$store.state.ScreenResize.currentWidth;
    },
  },
  watch: {
    onResize() {
      this.removeScrollTrigger();
      this.initAnimation();
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    requestAnimationFrame(() => {
      this.initAnimation();
    });
  },
  methods: {
    initAnimation() {
      if (!this.$vuetify.display['xs']) {
        gsap.set('.footer-container', {yPercent: -70});

        this.uncover = gsap.timeline({paused: true});
        this.uncover.to('.footer-container', {yPercent: 0, ease: 'none'});

        this.createScrollTrigger();
      } else {
        gsap.set('.footer-container', {yPercent: 0});
      }
    },
    createScrollTrigger() {
      // The 'end' value must represent the screen height (in viewport units) of the gsap-set element
      const windowHeight = window.innerHeight;
      const elementHeight = document.querySelector('.footer-container').clientHeight;
      const heightInVH = (elementHeight / windowHeight) * 100;
      const end = Math.round(heightInVH);

      this.myScrollTrigger = ScrollTrigger.create({
        trigger: '#project',
        start: 'bottom bottom',
        end: `+=${end}%`,
        animation: this.uncover,
        scrub: true,
      });
    },
    removeScrollTrigger() {
      if (this.myScrollTrigger) {
        this.myScrollTrigger.kill();
        this.myScrollTrigger = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.footer-container {
  @media #{map-get(vuetify.$display-breakpoints, 'xxl')} {
    padding-top: 130px;
    padding-bottom: 35px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'xl')} {
    padding-top: 130px;
    padding-bottom: 35px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'lg')} {
    padding-top: 130px;
    padding-bottom: 35px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'md')} {
    padding-top: 130px;
    padding-bottom: 35px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'sm')} {
    padding-top: 100px;
    padding-bottom: 35px;
  }
  @media #{map-get(vuetify.$display-breakpoints, 'xs')} {
    padding-top: 100px;
    padding-bottom: 35px;
  }
}

.footer-text {
  opacity: .3;
}

.underline-effect {
  $color: white;
  $bottom: -5px;

  color: $color;

  &:before {
    border-bottom: 1px solid rgba($color, 1);
    bottom: $bottom;
  }

  @media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
    &:before {
        border-bottom: 1px solid rgba($color, .4);
    }

    &:after {
        border-bottom: 1px solid rgba($color, 1);
        bottom: $bottom;
    }
  }
}
</style>
