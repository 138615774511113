<template>
  <div
    class="menu-toggle"
    :class="{'open': isOpen}"
  >
    <div class="hamburger">
      <span :class="{'hover': hover & $vuetify.display[$displaySizeUp]}" />
      <span :class="{'hover': hover & $vuetify.display[$displaySizeUp]}" />
    </div>
    <div class="cross">
      <span :class="{'hover': hover & $vuetify.display[$displaySizeUp]}" />
      <span :class="{'hover': hover & $vuetify.display[$displaySizeUp]}" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HamburgerSvg',
  components: {
  },
  props: {
    hover: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
$size: 50px;
$transition-duration: .10s;
$span-width: 70%;
$span-height: 4px;
$span-top-margin: 4px;

span {
  display: block;
  border-radius: 2px;
  transition: width $transition-duration ease-in-out, height $transition-duration ease-in-out;
  background-color: rgb(var(--v-theme-primary));
  &.hover {
    background-color: rgb(var(--v-theme-background));
  }
}

.menu-toggle {
  width: $size;
  height: $size;
  .hamburger {
    position: absolute;
    height: 100%;
    width: 100%;
    span {
      width: $span-width;
      height: $span-height;
      position: relative;
      margin: $span-top-margin 0;
      left: 15%;
      top: 30%;
      &:nth-child(1) {
        transition-delay: $transition-duration * 2;
      }
      &:nth-child(2) {
        transition-delay: $transition-duration * 2.5;
      }
    }
  }
  .cross {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(45deg);
    span{
      &:nth-child(1) {
        height: 0;
        width: 4px;
        position: absolute;
        top: 17.5%;
        left: 23px;
        transition-delay: 0s;
      }
      &:nth-child(2){
        width: 0;
        height: 4px;
        position: absolute;
        left: 17.5%;
        top: 23px;
        transition-delay: $transition-duration;
      }
    }
  }
}

.open {
  .hamburger {
    span {
      width: 0%;
      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2){
        transition-delay: .125s;
      }
    }
  }
  .cross {
    span {
      &:nth-child(1) {
        height: 65%;
        transition-delay: .625s;
      }
    }
    span {
      &:nth-child(2){
        width: 65%;
        transition-delay: .375s;
      }
    }
  }
}
</style>
