<template>
  <section
    id="landing"
    class="landing bg-background"
  >
    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
        }
      }"
      class="landing-container g-section-container"
      :class="{'landing-container-sm-and-up': $vuetify.display['smAndUp']}"
    >
      <v-row
        no-gutters
        class="text-left fill-height"
        :class="{'pb-16': $vuetify.display['smAndUp']}"
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          lg="12"
          order="2"
          order-lg="1"
        >
          <v-row
            class="fill-height overlay-col"
            align-content="space-evenly"
            no-gutters
          >
            <v-col cols="12">
              <p v-if="$vuetify.display[$displaySizeDown]" class="text-textcolor slide-in-bottom-1 pb-2" :class="{ 'entered': entered }">
                {{ $t('home.landing.introMobile') }}
              </p>

              <p v-else class="text-h5 slide-in-bottom-1 pb-2" :class="{ 'entered': entered }">
                {{ $t('home.landing.intro') }}
              </p>

              <div class="title-wrapper" @click="navigation.scrollTo('about')">
                <h1 class="title-text slide-in-bottom-2 pb-2" :class="{ 'entered': entered }">
                  <b>Marco Lanfranchi<span>.</span></b>
                </h1>

                <h2 class="subtitle-text slide-in-bottom-3 text-textcolor pb-2" :class="{ 'entered': entered }">
                  {{ $t('home.landing.subtitle') }}<span>.</span>
                </h2>
              </div>

              <p class="description-text text-textcolor slide-in-bottom-4" :class="{ 'entered': entered }">
                {{ $t('home.landing.description') }}
                <br v-if="$vuetify.display[$displaySizeUp]">
                {{ $t('home.landing.description2') }}

                <span v-if="$vuetify.display[$displaySizeUp]">
                  <TextRotator class="slide-in-bottom-4" :class="{ 'entered': entered }" />
                </span>
              </p>

              <div v-if="$vuetify.display[$displaySizeDown]" class="slide-in-bottom-4" :class="{ 'entered': entered }">
                <TextRotator />
              </div>
            </v-col>

            <v-col cols="12">
              <v-row
                no-gutters
                justify="start"
                class="scroll-landing slide-in-bottom-5"
                :class="{ 'pt-5': $vuetify.display[$displaySizeDown], 'pt-10': $vuetify.display[$displaySizeUp], 'entered': entered }"
              >
                <ScrollBtn
                  :go-to="'project'"
                  :custom-text="$t('home.landing.projectBtn')"
                  :bg-color="'background2'"
                  :parent-class="'.scroll-landing'"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <LandingMarquee v-if="$vuetify.display['smAndUp']" />
    </v-container>

    <LandingMarquee v-if="$vuetify.display['xs']" />
  </section>
</template>

<script>
import TextRotator from '@/pages/home/components/TextRotator.vue';
import ScrollBtn from '@/pages/home/components/ScrollBtn.vue';
import LandingMarquee from '@/pages/home/components/LandingMarquee.vue';
import {useIntersection} from '@/composables/intersection';
import useNavigation from '@/composables/navigation';

export default {
  name: 'LandingSection',
  components: {
    TextRotator,
    ScrollBtn,
    LandingMarquee,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    const navigation = useNavigation();
    return {entered, onIntersect, navigation};
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$landing-container-padding-top: (
  'xxl': 140px,
  'xl': 140px,
  'lg': 140px,
  'md': 140px,
  'sm': 100px,
  'xs': 120px,
);

$landing-container-padding-bottom: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

.landing-container {

  @include global.for-each-breakpoint('padding-top', $landing-container-padding-top);
  @include global.for-each-breakpoint('padding-bottom', $landing-container-padding-bottom);
}

.landing-container-sm-and-up {
  height: 100vh;
  min-height: 700px;
}

.title-wrapper {
  cursor: pointer;
}

.title-text, .subtitle-text {
  font-size: clamp(40px, 7vw, 80px) !important;
  font-style: normal;
  margin-left: -1px;
}

.title-text, .subtitle-text {
  line-height: initial;
}

.description-text {
  max-width: 540px
}
</style>
