<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      class="loader"
      :class="{'loader-active': enter}"
      width="100%"
      viewBox="0 0 802 78"
      fill="none"
      :style="`--duration-stroke-anim: ${durationStrokeAnim}s; --duration-fill-anim: ${durationFillAnim}s; --delay-fill-anim: ${delayFillAnim}s`"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.264 8.56801V75H61.528V25.464L39.448 75H33.304L11.128 25.368V75H2.392V8.56801H11.8L36.376 63.48L60.952 8.56801H70.264Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M118.776 65.496C117.176 68.504 114.616 71 111.096 72.984C107.576 74.968 103.703 75.96 99.4795 75.96C96.0875 75.96 93.0475 75.288 90.3595 73.944C87.6715 72.6 85.5595 70.744 84.0235 68.376C82.5515 66.008 81.8155 63.32 81.8155 60.312C81.8155 55.512 83.5755 51.576 87.0955 48.504C90.6155 45.368 95.7675 43.8 102.551 43.8H118.776C118.648 39.256 117.4 35.672 115.032 33.048C112.728 30.424 109.528 29.112 105.432 29.112C101.72 29.112 98.6475 30.136 96.2155 32.184C93.8475 34.232 92.3435 37.08 91.7035 40.728H83.1595C83.9275 34.968 86.2955 30.328 90.2635 26.808C94.2955 23.288 99.4795 21.528 105.815 21.528C109.975 21.528 113.688 22.424 116.951 24.216C120.28 26.008 122.872 28.6 124.728 31.992C126.648 35.384 127.607 39.448 127.607 44.184V75H118.776V65.496ZM101.208 68.28C104.152 68.28 106.968 67.64 109.656 66.36C112.344 65.08 114.52 63.192 116.184 60.696C117.912 58.2 118.776 55.224 118.776 51.768V50.904H102.551C98.5835 50.904 95.6075 51.704 93.6235 53.304C91.6395 54.904 90.6475 57.048 90.6475 59.736C90.6475 62.296 91.5755 64.376 93.4315 65.976C95.3515 67.512 97.9435 68.28 101.208 68.28Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M151.191 30.936C152.727 27.928 154.903 25.592 157.719 23.928C160.599 22.264 164.086 21.432 168.182 21.432V30.456H165.878C156.086 30.456 151.191 35.768 151.191 46.392V75H142.454V22.392H151.191V30.936Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M175.003 48.6C175.003 43.16 176.091 38.424 178.267 34.392C180.443 30.296 183.451 27.128 187.291 24.888C191.195 22.648 195.643 21.528 200.635 21.528C207.099 21.528 212.411 23.096 216.571 26.232C220.795 29.368 223.579 33.72 224.923 39.288H215.515C214.619 36.088 212.859 33.56 210.235 31.704C207.675 29.848 204.475 28.92 200.635 28.92C195.643 28.92 191.611 30.648 188.539 34.104C185.467 37.496 183.931 42.328 183.931 48.6C183.931 54.936 185.467 59.832 188.539 63.288C191.611 66.744 195.643 68.472 200.635 68.472C204.475 68.472 207.675 67.576 210.235 65.784C212.795 63.992 214.555 61.432 215.515 58.104H224.923C223.515 63.48 220.699 67.8 216.475 71.064C212.251 74.264 206.971 75.864 200.635 75.864C195.643 75.864 191.195 74.744 187.291 72.504C183.451 70.264 180.443 67.096 178.267 63C176.091 58.904 175.003 54.104 175.003 48.6Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M259.523 75.864C254.595 75.864 250.115 74.744 246.083 72.504C242.115 70.264 238.98 67.096 236.676 63C234.436 58.84 233.316 54.04 233.316 48.6C233.316 43.224 234.467 38.488 236.771 34.392C239.139 30.232 242.34 27.064 246.372 24.888C250.404 22.648 254.916 21.528 259.908 21.528C264.9 21.528 269.412 22.648 273.444 24.888C277.476 27.064 280.644 30.2 282.948 34.296C285.316 38.392 286.5 43.16 286.5 48.6C286.5 54.04 285.284 58.84 282.852 63C280.484 67.096 277.252 70.264 273.156 72.504C269.06 74.744 264.515 75.864 259.523 75.864ZM259.523 68.184C262.659 68.184 265.603 67.448 268.355 65.976C271.108 64.504 273.315 62.296 274.979 59.352C276.707 56.408 277.572 52.824 277.572 48.6C277.572 44.376 276.74 40.792 275.076 37.848C273.412 34.904 271.236 32.728 268.548 31.32C265.86 29.848 262.947 29.112 259.811 29.112C256.611 29.112 253.668 29.848 250.98 31.32C248.356 32.728 246.243 34.904 244.643 37.848C243.043 40.792 242.243 44.376 242.243 48.6C242.243 52.888 243.012 56.504 244.548 59.448C246.148 62.392 248.259 64.6 250.883 66.072C253.507 67.48 256.387 68.184 259.523 68.184Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M332.315 67.896H355.74V75H323.579V8.08801H332.315V67.896Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M398.713 65.496C397.113 68.504 394.553 71 391.033 72.984C387.513 74.968 383.641 75.96 379.417 75.96C376.025 75.96 372.985 75.288 370.297 73.944C367.609 72.6 365.497 70.744 363.961 68.376C362.489 66.008 361.753 63.32 361.753 60.312C361.753 55.512 363.513 51.576 367.033 48.504C370.553 45.368 375.705 43.8 382.489 43.8H398.713C398.585 39.256 397.337 35.672 394.969 33.048C392.665 30.424 389.465 29.112 385.369 29.112C381.657 29.112 378.585 30.136 376.153 32.184C373.785 34.232 372.281 37.08 371.641 40.728H363.097C363.865 34.968 366.233 30.328 370.201 26.808C374.233 23.288 379.417 21.528 385.753 21.528C389.913 21.528 393.625 22.424 396.889 24.216C400.217 26.008 402.809 28.6 404.665 31.992C406.585 35.384 407.545 39.448 407.545 44.184V75H398.713V65.496ZM381.145 68.28C384.089 68.28 386.905 67.64 389.593 66.36C392.281 65.08 394.457 63.192 396.121 60.696C397.849 58.2 398.713 55.224 398.713 51.768V50.904H382.489C378.521 50.904 375.545 51.704 373.561 53.304C371.577 54.904 370.585 57.048 370.585 59.736C370.585 62.296 371.513 64.376 373.369 65.976C375.289 67.512 377.881 68.28 381.145 68.28Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M448.024 21.432C454.424 21.432 459.608 23.384 463.576 27.288C467.544 31.128 469.528 36.696 469.528 43.992V75H460.888V45.24C460.888 39.992 459.576 35.992 456.952 33.24C454.328 30.424 450.744 29.016 446.2 29.016C441.592 29.016 437.912 30.456 435.16 33.336C432.472 36.216 431.128 40.408 431.128 45.912V75H422.392V22.392H431.128V29.88C432.856 27.192 435.192 25.112 438.136 23.64C441.144 22.168 444.44 21.432 448.024 21.432Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M505.206 29.592H494.166V75H485.43V29.592H478.614V22.392H485.43V18.648C485.43 12.76 486.934 8.47201 489.942 5.78401C493.014 3.03201 497.91 1.65601 504.63 1.65601V8.95201C500.79 8.95201 498.07 9.72001 496.47 11.256C494.934 12.728 494.166 15.192 494.166 18.648V22.392H505.206V29.592Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M524.128 30.936C525.664 27.928 527.84 25.592 530.656 23.928C533.536 22.264 537.024 21.432 541.12 21.432V30.456H538.816C529.024 30.456 524.128 35.768 524.128 46.392V75H515.392V22.392H524.128V30.936Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M584.9 65.496C583.3 68.504 580.74 71 577.22 72.984C573.7 74.968 569.828 75.96 565.604 75.96C562.212 75.96 559.172 75.288 556.484 73.944C553.796 72.6 551.684 70.744 550.148 68.376C548.676 66.008 547.94 63.32 547.94 60.312C547.94 55.512 549.7 51.576 553.22 48.504C556.74 45.368 561.892 43.8 568.676 43.8H584.9C584.772 39.256 583.524 35.672 581.156 33.048C578.852 30.424 575.652 29.112 571.556 29.112C567.844 29.112 564.772 30.136 562.34 32.184C559.972 34.232 558.468 37.08 557.828 40.728H549.284C550.052 34.968 552.42 30.328 556.388 26.808C560.42 23.288 565.604 21.528 571.94 21.528C576.1 21.528 579.812 22.424 583.076 24.216C586.404 26.008 588.996 28.6 590.852 31.992C592.772 35.384 593.732 39.448 593.732 44.184V75H584.9V65.496ZM567.332 68.28C570.276 68.28 573.092 67.64 575.78 66.36C578.468 65.08 580.644 63.192 582.308 60.696C584.036 58.2 584.9 55.224 584.9 51.768V50.904H568.676C564.708 50.904 561.732 51.704 559.748 53.304C557.764 54.904 556.772 57.048 556.772 59.736C556.772 62.296 557.7 64.376 559.556 65.976C561.476 67.512 564.068 68.28 567.332 68.28Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M634.211 21.432C640.611 21.432 645.795 23.384 649.763 27.288C653.731 31.128 655.715 36.696 655.715 43.992V75H647.075V45.24C647.075 39.992 645.763 35.992 643.139 33.24C640.515 30.424 636.931 29.016 632.387 29.016C627.779 29.016 624.099 30.456 621.347 33.336C618.659 36.216 617.315 40.408 617.315 45.912V75H608.579V22.392H617.315V29.88C619.043 27.192 621.379 25.112 624.323 23.64C627.331 22.168 630.627 21.432 634.211 21.432Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M666.722 48.6C666.722 43.16 667.81 38.424 669.986 34.392C672.162 30.296 675.17 27.128 679.01 24.888C682.914 22.648 687.362 21.528 692.354 21.528C698.818 21.528 704.13 23.096 708.29 26.232C712.514 29.368 715.298 33.72 716.642 39.288H707.234C706.338 36.088 704.578 33.56 701.954 31.704C699.394 29.848 696.194 28.92 692.354 28.92C687.362 28.92 683.33 30.648 680.258 34.104C677.186 37.496 675.65 42.328 675.65 48.6C675.65 54.936 677.186 59.832 680.258 63.288C683.33 66.744 687.362 68.472 692.354 68.472C696.194 68.472 699.394 67.576 701.954 65.784C704.514 63.992 706.274 61.432 707.234 58.104H716.642C715.234 63.48 712.418 67.8 708.194 71.064C703.97 74.264 698.69 75.864 692.354 75.864C687.362 75.864 682.914 74.744 679.01 72.504C675.17 70.264 672.162 67.096 669.986 63C667.81 58.904 666.722 54.104 666.722 48.6Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M754.41 21.432C758.378 21.432 761.962 22.296 765.162 24.024C768.362 25.688 770.858 28.216 772.65 31.608C774.506 35 775.434 39.128 775.434 43.992V75H766.794V45.24C766.794 39.992 765.482 35.992 762.858 33.24C760.234 30.424 756.65 29.016 752.106 29.016C747.498 29.016 743.818 30.456 741.066 33.336C738.378 36.216 737.034 40.408 737.034 45.912V75H728.298V3.96001H737.034V29.88C738.762 27.192 741.13 25.112 744.138 23.64C747.21 22.168 750.634 21.432 754.41 21.432Z"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M794.216 13.848C792.552 13.848 791.144 13.272 789.992 12.12C788.84 10.968 788.264 9.56001 788.264 7.89601C788.264 6.23201 788.84 4.82401 789.992 3.67201C791.144 2.52001 792.552 1.94401 794.216 1.94401C795.816 1.94401 797.16 2.52001 798.248 3.67201C799.4 4.82401 799.976 6.23201 799.976 7.89601C799.976 9.56001 799.4 10.968 798.248 12.12C797.16 13.272 795.816 13.848 794.216 13.848ZM798.44 22.392V75H789.704V22.392H798.44Z"
        stroke="white"
        stroke-width="3"
      />
    </svg>
  </v-row>
</template>

<script>
export default {
  name: 'LoaderSvg',
  props: {
    enter: {
      type: Boolean,
      required: true,
    },
    animDurationSeconds: {
      type: Number,
      required: true,
    },
  },
  computed: {
    durationStrokeAnim() {
      return this.animDurationSeconds / 2;
    },
    durationFillAnim() {
      return this.animDurationSeconds >= 4 ? 1 : this.animDurationSeconds / 4;
    },
    delayFillAnim() {
      return this.animDurationSeconds >= 4 ? (this.animDurationSeconds / 2) + 1 : (this.animDurationSeconds / 4) * 3;
    },
  },
  mounted() {
    // Retrieve stroke lenght of each letter
    // const loader = document.querySelectorAll('.loader-active path');

    // console.log(loader);

    // for (let i=0; i<loader.length; i++) {
    //   console.log(`Letter  ${i} is ${loader[i].getTotalLength()}`);
    // }
  },
};
</script>

<style scoped lang="scss">
$stroke-color: whitesmoke;
$durationStrokeAnim: var(--duration-stroke-anim);
$durationFillAnim: var(--duration-fill-anim);
$delayFillAnim: var(--delay-fill-anim);
$stroke-lengths: 503.28, 339.68, 164.01, 265.95, 289.18, 198.14, 339.68, 281.66, 210.71, 164.01, 339.68, 281.66, 265.95, 318.51, 159.87;

.loader {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.loader-active path {
  stroke: $stroke-color;
}

.loader-active {
  animation: fill $durationFillAnim ease forwards $delayFillAnim;

  @for $i from 1 through length($stroke-lengths) {
    path:nth-child(#{$i}) {
      $stroke-length: nth($stroke-lengths, $i);
      stroke-dasharray: $stroke-length;
      stroke-dashoffset: $stroke-length;
      animation: line-anim $durationStrokeAnim ease #{($i - 1) * 100ms} forwards;
    }
  }
}

@keyframes line-anim{
  to{
    stroke-dashoffset: 0;
  }
}

@keyframes fill{
  from{
    fill: transparent;
  }
  to{
    fill: $stroke-color;
  }
}
</style>
