/* eslint-disable max-len */
import {ProjectInterface} from '@/models/project';

const descriptionTranslation = 'home.projects.workInProgress.description';

const WORK_IN_PROGRESS: ProjectInterface = {
  title: 'Work in progress',
  description: descriptionTranslation,
  logo: 'test.png',
  price: '',
  link: '',
  platforms: [],
  technologies: [],
};

export default WORK_IN_PROGRESS;
