<template>
  <section
    id="work"
    class="bg-background"
  >
    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
        }
      }"
      class="work-container g-section-container"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <SectionTitle
            :title="'home.work.title'"
            class="slide-in-bottom-1"
            :class="{ 'entered': entered }"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          cols="12"
          lg="4"
          md="5"
          order-md="0"
          order="2"
          class="g-svg-section slide-in-bottom-2"
          :class="{'pt-13': $vuetify.display.smAndDown, 'pt-0': $vuetify.display.xxl, 'pt-5': $vuetify.display.xlAndDown, 'entered': entered}"
        >
          <WorkSvg />
        </v-col>

        <v-spacer />

        <v-col
          cols="12"
          md="6"
        >
          <WorksTimeline />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SectionTitle from '@/pages/home/components/SectionTitle.vue';
import WorksTimeline from '@/pages/home/components/WorksTimeline.vue';
import WorkSvg from '@/pages/home/assets/svg/WorkSvg.vue';
import {useIntersection} from '@/composables/intersection';

export default {
  name: 'WorkSection',
  components: {
    SectionTitle,
    WorksTimeline,
    WorkSvg,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    return {entered, onIntersect};
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$work-container-padding-top: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

$work-container-padding-bottom: (
  'xxl': 100px,
  'xl': 70px,
  'lg': 70px,
  'md': 70px,
  'sm': 70px,
  'xs': 30px,
);

.work-container {
  @include global.for-each-breakpoint('padding-top', $work-container-padding-top);
  @include global.for-each-breakpoint('padding-bottom', $work-container-padding-bottom);
}
</style>
