<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <g
        id="layer1"
        inkscape:label="Calque 1"
        inkscape:groupmode="layer"
        transform="translate(-193.633,-739.48031)"
      >
        <polygon
          id="polygon2989"
          points="437.367,100.62 404.321,470.819 255.778,512 107.644,470.877 74.633,100.62 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
        <polygon
          id="polygon2991"
          points="256,480.523 376.03,447.246 404.27,130.894 256,130.894 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
        <polygon
          id="polygon2993"
          points="256,268.217 150.31,268.217 154.38,313.627 256,313.627 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
        <polygon
          id="polygon2995"
          points="256,176.305 255.843,176.305 142.132,176.305 146.26,221.716 256,221.716 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
        <polygon
          id="polygon2997"
          points="156.409,336.333 162.771,407.634 255.791,433.457 256,433.399 256,386.153 255.801,386.206 205.227,372.55 201.994,336.333 177.419,336.333 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
        <polygon
          id="polygon3005"
          points="311.761,313.627 306.49,372.521 255.843,386.191 255.843,433.435 348.937,407.634 349.62,399.962 360.291,280.411 361.399,268.217 369.597,176.305 255.843,176.305 255.843,221.716 319.831,221.716 315.699,268.217 255.843,268.217 255.843,313.627 "
          transform="matrix(0.15557406,0,0,0.15557392,185.82204,723.82646)"
        />
      </g>
    </svg>
  </v-row>
</template>

<style>
#polygon2989 {
    fill: #264de4;
}
#polygon2991 {
    fill: #2965f1;
}
#polygon2993 {
    fill: #ebebeb;
}
#polygon2995 {
    fill: #ebebeb;
}
#polygon2997 {
    fill: #ebebeb;
}
#polygon3005 {
    fill: #ffffff;
}
</style>
