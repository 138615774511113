import {ref, watch} from 'vue';
import {useStore} from '@/store';

export function useIntersection() {
  const entered = ref(false);
  const isIntersecting = ref(false);
  const store = useStore();

  const checkIntersection = (isLoading: boolean) => {
    if (!entered.value && isIntersecting.value && !isLoading) {
      entered.value = true;
    }
  };

  const onIntersect = (intersecting: boolean) => {
    isIntersecting.value = intersecting;
    checkIntersection(store.state.LoaderManager.isLoading);
  };

  watch(
      () => store.state.LoaderManager.isLoading,
      (newValue) => {
        checkIntersection(newValue);
      },
  );

  return {
    entered,
    onIntersect,
  };
}
