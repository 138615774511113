<template>
  <section
    id="proficiency"
    class="bg-background2"
  >
    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: $vuetify.display[$displaySizeUp] ? 0.3 : 0
        }
      }"
      class="proficiency-container g-section-container"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <SectionTitle
            :title="'home.proficiency.title'"
            class="slide-in-bottom-1"
            :class="{ 'entered': entered }"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <p class="proficiency-text text-textcolor slide-in-bottom-2 pb-5" :class="{ 'entered': entered }">
            {{ $t('home.proficiency.description') }}
          </p>

          <p class="proficiency-text text-textcolor pb-10 slide-in-bottom-3" :class="{ 'entered': entered }">
            {{ $t('home.proficiency.description2') }}
          </p>

          <ProficiencyMarquee class="slide-in-bottom-4" :class="{ 'entered': entered }" />
        </v-col>

        <v-spacer />

        <v-col
          cols="12"
          lg="4"
          md="5"
          class="g-svg-section slide-in-bottom-4"
          :class="{'pt-13': $vuetify.display.smAndDown, 'entered': entered}"
          align-self="end"
        >
          <ProficienciesSvg />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SectionTitle from '@/pages/home/components/SectionTitle.vue';
import ProficiencyMarquee from '@/pages/home/components/ProficiencyMarquee.vue';
import ProficienciesSvg from '@/pages/home/assets/svg/ProficienciesSvg.vue';
import {proficiencyData} from '@/pages/home/data/proficiencies';
import {useIntersection} from '@/composables/intersection';

export default {
  name: 'ProficiencySection',
  components: {
    SectionTitle,
    ProficiencyMarquee,
    ProficienciesSvg,
  },
  setup() {
    const {entered, onIntersect} = useIntersection();
    return {entered, onIntersect};
  },
  data: () => ({
    proficiencies: proficiencyData,
  }),
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$proficiency-container-padding-top: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

$proficiency-container-padding-bottom: (
  'xxl': 100px,
  'xl': 100px,
  'lg': 100px,
  'md': 100px,
  'sm': 70px,
  'xs': 70px,
);

.proficiency-container {
  @include global.for-each-breakpoint('padding-top', $proficiency-container-padding-top);
  @include global.for-each-breakpoint('padding-bottom', $proficiency-container-padding-bottom);
}

.skills {
  margin-left: -5px;
}
</style>
