<template>
  <v-navigation-drawer
    v-model="isOpen"
    location="right"
    class="drawer"
    temporary
    elevation="20"
    :width="widthPx"
  >
    <v-container class="drawer-wrapper pt-0 pb-0 pr-0">
      <v-row
        no-gutters
      >
        <v-col cols="12">
          <p class="drawer-logo">
            LOGO
          </p>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="fill-height"
        align-content="space-evenly"
      >
        <v-col cols="12">
          <v-row
            no-gutters
            justify="center"
            align="start"
            align-content="center"
          >
            <v-col
              v-show="$vuetify.display.mdAndUp"
              cols="4"
            >
              <v-row
                no-gutters
              >
                <span class="pb-5 drawer-title"> <b>Social</b> </span>
                <template
                  v-for="(social, i) in socials"
                  :key="i"
                >
                  <v-col
                    class="pt-4"
                    cols="12"
                  >
                    <SocialRollingText
                      :inner-text="social.name"
                      :link="social.link"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              md="8"
            >
              <v-row
                no-gutters
                justify="start"
                align="center"
                align-content="center"
              >
                <span class="pb-5 drawer-title"> <b>Menu</b> </span>
                <template
                  v-for="(route, i) in routes"
                  :key="i"
                >
                  <v-col
                    class="pt-0"
                    cols="12"
                  >
                    <MenuRollingText
                      :text="route.name"
                      :go-to="route.path"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          class="drawer-trailing"
        >
          <v-row no-gutters>
            <span class="drawer-title pb-1"> <b>{{ $t('drawer.getInTouch') }}</b> </span>
            <v-col cols="12">
              <a
                class="underline-effect"
                :href="socialsEnum.EMAIL.link"
                target="_blank"
              >
                <b>{{ socialsEnum.EMAIL.link.replace(/^mailto:/i, '') }}</b>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import useNavigation from '@/composables/navigation';
import {SocialsData, SocialsEnum} from '@/pages/common/data/socials';
import NavigationRoutesData from '@/pages/common/data/navigation-routes';
import SocialRollingText from '@/pages/common/components/SocialRollingText.vue';
import MenuRollingText from '@/pages/common/components/MenuRollingText.vue';

export default {
  name: 'NavigationDrawer',
  components: {
    SocialRollingText,
    MenuRollingText,
  },
  setup() {
    const navigation = useNavigation();
    return {navigation};
  },
  data: () => ({
    isOpen: false,
    socials: SocialsData,
    socialsEnum: SocialsEnum,
    routes: NavigationRoutesData,
    widthPx: 100,
  }),
  computed: {
    toggleMenu() {
      return this.$store.state.MenuManager.isOpen;
    },
    handleTemporaryClousere() {
      return this.isOpen;
    },
    onResize() {
      return this.$store.state.ScreenResize.currentWidth;
    },
  },
  watch: {
    toggleMenu() {
      this.isOpen = this.$store.state.MenuManager.isOpen;
      this.isOpen ? document.documentElement.style.overflow = 'hidden' : document.documentElement.style.overflow = 'auto';
    },
    handleTemporaryClousere() {
      if (this.isOpen != this.$store.state.MenuManager.isOpen) {
        this.$store.dispatch('toggleMenu');
      }
    },
    onResize() {
      this.vwToPx();
    },
  },

  mounted() {
    this.vwToPx();
  },
  methods: {
    scrollTo(path) {
      this.$store.dispatch('toggleMenu');
      this.navigation.scrollTo(path);
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    vwToPx() {
      let value;

      if (this.$vuetify.display.xxl) {
        value = 35;
      } else if (this.$vuetify.display.xl) {
        value = 40;
      } else if (this.$vuetify.display.lg) {
        value = 50;
      } else if (this.$vuetify.display.md) {
        value = 55;
      } else if (this.$vuetify.display.sm) {
        value = 100;
      } else {
        value = 100;
      }

      const displayWidth = window.innerWidth;
      this.widthPx = (value * displayWidth) / 100;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

.drawer-social > .v-btn__overlay {
  background-color: transparent;
}
.drawer-trailing-btn > .v-btn__overlay {
  background-color: transparent;
}
.drawer {
  transition-duration: .4s;
}
</style>


<style lang="scss" scoped>
@use "@/assets/scss/styles" as global;
@use "vuetify/styles/settings/variables" as vuetify;

$drawer-wrapper-padding-left: (
  'xxl': 150px,
  'xl': 75px,
  'lg': 60px,
  'md': 50px,
  'sm': 40px,
  'xs': 25px,
);

// Padding top is same as nav-top
$logo-padding-top: (
  'xxl': 54px,
  'xl': 54px,
  'lg': 49px,
  'md': 49px,
  'sm': 43px,
  'xs': 33px,
);

$trailing-padding-bottom: (
  'xxl': 54px,
  'xl': 54px,
  'lg': 49px,
  'md': 49px,
  'sm': 43px,
  'xs': 43px,
);

.drawer-social {
  margin-left: -8px;
}

.drawer-title {
  color: rgba(var(--v-theme-primary), .4);
}

.drawer-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include global.for-each-breakpoint('padding-left', $drawer-wrapper-padding-left, true);
}

.drawer-logo {
  @include global.for-each-breakpoint('padding-top', $logo-padding-top);
}

.drawer-trailing {
  @include global.for-each-breakpoint('padding-bottom', $trailing-padding-bottom, true);
}

.underline-effect {
  $bottom: -5px;

  &:before {
    bottom: $bottom;
  }

  @media #{map-get(vuetify.$display-breakpoints, global.$display-size-up)} {
    &:after {
      bottom: $bottom;
    }
  }
}
</style>
