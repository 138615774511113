// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Icons
import {aliases, mdi} from 'vuetify/iconsets/mdi';

// Icons Platforms
import AndroidSvg from '@/pages/home/assets/svg/platforms/AndroidSvg.vue';
import InternetSvg from '@/pages/home/assets/svg/platforms/InternetSvg.vue';

// Icons Technologies
import CsharpSvg from '@/pages/home/assets/svg/technologies/CsharpSvg.vue';
import CssSvg from '@/pages/home/assets/svg/technologies/CssSvg.vue';
import DartSvg from '@/pages/home/assets/svg/technologies/DartSvg.vue';
import FirebaseSvg from '@/pages/home/assets/svg/technologies/FirebaseSvg.vue';
import FlutterSvg from '@/pages/home/assets/svg/technologies/FlutterSvg.vue';
import GitSvg from '@/pages/home/assets/svg/technologies/GitSvg.vue';
import GolangSvg from '@/pages/home/assets/svg/technologies/GolangSvg.vue';
import HtmlSvg from '@/pages/home/assets/svg/technologies/HtmlSvg.vue';
import PostgresSvg from '@/pages/home/assets/svg/technologies/PostgresSvg.vue';
import UnitySvg from '@/pages/home/assets/svg/technologies/UnitySvg.vue';
import VueSvg from '@/pages/home/assets/svg/technologies/VueSvg.vue';
import VuetifySvg from '@/pages/home/assets/svg/technologies/VuetifySvg.vue';

// Vuetify
import {createVuetify, ThemeDefinition} from 'vuetify';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#141414',
    secondary: '#228B22',
    background: '#F5F5F5',
    background2: '#FFFFFF',
    textcolor: '#4b5555',
    footer: '#212121',
    worktab: '#8b8b8b',
    projecttext: '#b8b8b8',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
    },
    aliases: {
      ...aliases,

      // Platforms
      android: AndroidSvg,
      internet: InternetSvg,

      // Technologies
      csharp: CsharpSvg,
      css: CssSvg,
      dart: DartSvg,
      firebase: FirebaseSvg,
      flutter: FlutterSvg,
      git: GitSvg,
      golang: GolangSvg,
      html: HtmlSvg,
      postgres: PostgresSvg,
      unity: UnitySvg,
      vue: VueSvg,
      vuetify: VuetifySvg,
    },
  },
});
