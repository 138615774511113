import {cursor} from '@/translations/it/cursor';
import {drawer} from '@/translations/it/drawer';
import {home} from '@/translations/it/home-page/index';
import {footer} from '@/translations/it/footer';

export const it = {
  cursor,
  drawer,
  home,
  footer,
};
