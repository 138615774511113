export default class Social {
  name: string;
  link: string;
  icon: string;

  constructor(name: string, link: string, icon: string) {
    this.name = name;
    this.link = link;
    this.icon = icon;
  }
}
