<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <path
        id="path4"
        d="M 39.33333,5.5 32,18.33333 24.666667,5.5 H 0 L 32,61 64,5.5 Z"
        inkscape:connector-curvature="0"
      />
      <path
        id="path6"
        d="M 39.33333,5.5 32,18.33333 24.666667,5.5 H 12.833333 L 32,38.83333 51.16667,5.5 Z"
        inkscape:connector-curvature="0"
      />
    </svg>
  </v-row>
</template>

<style scoped>
#path4{
    fill:#42b883;stroke-width:0.16666667
}
#path6 {
    fill:#35495e;stroke-width:0.16666667
}
</style>
