<!-- eslint-disable max-len -->
<template>
  <v-row no-gutters>
    <svg
      viewBox="0 0 54 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <path
        d="M15.9 0.468002L18.78 5.608C13.43 8.28 9.73002 13.4 9.73002 19.384H44.27C44.27 13.424 40.57 8.284 35.224 5.608L38.104 0.468002C38.104 0.468002 38.31 0.262002 37.898 0.0560015C37.692 -0.149998 37.486 0.262002 37.486 0.262002L34.4 5.404C32.0074 4.32844 29.4153 3.76831 26.792 3.76C24.1688 3.76831 21.5766 4.32844 19.184 5.404L16.104 0.264002C16.104 0.264002 15.9 0.0600015 15.692 0.0600015C15.7137 0.216187 15.7861 0.360931 15.898 0.472002L15.9 0.468002ZM18.8 10.54C19.6 10.54 20.444 11.34 20.444 12.184C20.444 13.028 19.644 13.828 18.8 13.828C18.5832 13.8312 18.368 13.7909 18.1671 13.7095C17.9662 13.628 17.7836 13.507 17.6303 13.3537C17.477 13.2004 17.356 13.0179 17.2746 12.8169C17.1931 12.616 17.1528 12.4008 17.156 12.184C17.1528 11.9672 17.1931 11.752 17.2746 11.5511C17.356 11.3501 17.477 11.1676 17.6303 11.0143C17.7836 10.861 17.9662 10.74 18.1671 10.6586C18.368 10.5771 18.5832 10.5368 18.8 10.54V10.54ZM35.248 10.54C36.048 10.54 36.892 11.34 36.892 12.184C36.892 13.028 36.092 13.828 35.248 13.828C35.0312 13.8312 34.816 13.7909 34.6151 13.7095C34.4142 13.628 34.2316 13.507 34.0783 13.3537C33.925 13.2004 33.804 13.0179 33.7226 12.8169C33.6411 12.616 33.6008 12.4008 33.604 12.184C33.6008 11.9672 33.6411 11.752 33.7226 11.5511C33.804 11.3501 33.925 11.1676 34.0783 11.0143C34.2316 10.861 34.4142 10.74 34.6151 10.6586C34.816 10.5771 35.0312 10.5368 35.248 10.54V10.54ZM9.52402 21.44V47.76C9.52402 48.994 11.168 50.844 12.404 50.844H16.516V59.89C16.516 62.15 18.16 64.002 20.216 64.002C22.272 64.002 23.916 62.152 23.916 59.89V50.84H29.876V59.886C29.876 62.146 31.52 63.998 33.576 63.998C35.632 63.998 37.276 62.148 37.276 59.886V50.84H41.388C42.622 50.84 44.268 49.196 44.268 47.756V21.44H9.52402Z"
        fill="black"
        fill-opacity="0.5"
      />
      <path
        d="M3.76601 20.824C5.82201 20.824 7.46601 22.674 7.46601 24.936V40.562C7.46601 42.822 5.82201 44.674 3.76601 44.674C1.71001 44.674 0.0660095 42.824 0.0660095 40.562V24.936C0.0660095 22.676 1.71001 20.824 3.76601 20.824V20.824Z"
        fill="black"
        fill-opacity="0.5"
      />
      <path
        d="M50.234 20.824C52.29 20.824 53.934 22.674 53.934 24.936V40.562C53.934 42.822 52.29 44.674 50.234 44.674C48.178 44.674 46.534 42.824 46.534 40.562V24.936C46.534 22.676 48.178 20.824 50.234 20.824V20.824Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  </v-row>
</template>
