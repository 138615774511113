<!-- eslint-disable max-len -->
<template>
  <div class="wave">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
        class="shape-fill"
        :style="cssVars"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WaveSvg',
  props: {
    fill: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssVars() {
      return {
        '--fill_color': this.$vuetify.theme.current.colors[this.fill],
      };
    },
  },
};
</script>

<style scoped lang="scss">

// https://www.youtube.com/watch?v=lPJVi797Uy0&t=24s
.wave {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave svg {
  position: relative;
  display: block;
  width: calc(140% + 1.3px);
  height: 83px;
}

.wave .shape-fill {
  fill: var(--fill_color);
}

/** For mobile devices **/
@media (max-width: 767px) {
    .wave svg {
        width: calc(170% + 1.3px);
        height: 90px;
    }
}
</style>
